import React, { FC, useState } from 'react';
import './AccountAddInfos.scss';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import AccountStatus from './AccountStatus/AccountStatus';
import AcsDocumentation from './AcsDocumentation/AcsDocumentation';
import AccountsAndPermissions from './AccountsAndPermissions/AccountsAndPermissions';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  containner: {
    minWidth: '1200px',
  },
  snackBar: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const AccountList = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [message, setMessage] = useState('');

  const handleCloseAlert = () => {
    setOpen(false);
  };

  return (
    <div className="add-info">
      <div className="add-info__header">Additional Info</div>
      <AcsDocumentation />
      <AccountStatus />
      <AccountsAndPermissions
        handleAlert={(value: any) => {
          setAlertType(value.type);
          setOpen(value.open);
          setMessage(value.message);
        }}
      />
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        className={classes.snackBar}
      >
        <Alert onClose={handleCloseAlert} severity={alertType}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AccountList;
