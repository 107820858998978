import React from 'react';
import './IntroPage.scss';
import audi_rings_light from '../../assets/images/Audi_Rings_Light_wh-RGB.png';

const IntroPage = () => {
  const now = new Date();
  let message = 'Good Morning';
  if (now.getHours() > 12) {
    message = 'Good Afternoon';
  } if (now.getHours() > 16) {
    message = 'Good Evening';
  }

  return (
    <div className="intro">
      <div className="intro__inner">
        <div className="intro_header">
          <h4>
            {message}
            &nbsp;and Welcome to the
          </h4>
          <span className="title">Audi Cloud Services</span>
        </div>
        <div className="intro_body">
          <h2>Why do I see this page?</h2>
          <p>
            It seems like you wanted to create an AWS Account using your internal e-mail address
            <br />
            which can not be done directly in AWS.
            <br />
            This is by far no problem, things just work a little different and even better for you!
          </p>
          <h2>How can I get an AWS Account?</h2>
          <p>
            The Audi Cloud Services offer you a carfree package including:
            <ul>
              <li>Account Management Tool</li>
              <li>Foundation Services</li>
              <li>Security Services</li>
              <li>Consulting and Templates</li>
            </ul>
            Click the following link and you will find everything you need at a glance!
          </p>
          {/* TBD IMPLEMENT BUTTON LINK */}
          <form action="https://volkswagen-net.de/wikis/x/fiz2VQ">
            <button type="submit" value="VW GroupWiki" className="audi-button">
              Take me to the cloud&nbsp;
              <span className="red-arrow">
                &#5171;
              </span>
            </button>
          </form>
        </div>
      </div>
      <img src={audi_rings_light} alt="Audi Rings Light" />
    </div>
  );
};

export default IntroPage;
