import React, { useEffect, useState } from 'react';
import { Checkbox, RadioButton } from '@audi/audi-ui-react';
import './AccountCreationSteps.scss';
import { useDispatch, useSelector } from 'react-redux';
import { editAccount, filterAccountNames } from '../../app/slices/accountListSlice';
import { setIsCompletedFilled } from '../../app/slices/accountCreationStepsSlice';

interface RootState {
  accounts: {
    account: Account;
    accountNameAvailable: boolean;
  }
}

interface Account {
 [key: string]: string,
}

const CreationStepThree = () => {
  const account = useSelector((state: RootState) => state.accounts.account);
  const availability = useSelector((state: RootState) => state.accounts.accountNameAvailable);
  const [aliasLimit, setAliasLimit] = useState<boolean>(true);
  const dispatch = useDispatch();

  const accountTypes = [
    {
      name: 'sandbox',
      value: 'sandbox',
      label: 'Sandbox - Account',
      active: false,
    },
    {
      name: 'dev',
      value: 'dev',
      label: 'DEV - Account',
      active: true,
    },
    {
      name: 'test',
      value: 'test',
      label: 'Test - Account',
      active: true,
    },
    {
      name: 'prod',
      value: 'prd',
      label: 'Prod - Account',
      active: true,
    },
  ];

  const filterAccountName = async (selected_env: any) => {
    const account_name = `${account.account_brand}-${selected_env}-${account.account_project}-${account.account_spec}`;
    try {
      await dispatch(filterAccountNames({ filterName: 'account_name', filterValue: account_name }));
    } catch (error) {
      return error;
    }
  };

  const calculateAliasLimit = () => {
    const alias = `${account.account_brand}-${account.account_env}-${account.project_name}-${account.account_spec}-project-account`;
    return alias.length < 64 ? setAliasLimit(false) : setAliasLimit(true);
  };

  useEffect(() => {
    calculateAliasLimit();
    if (account.account_env && availability && !aliasLimit) {
      dispatch(setIsCompletedFilled(true));
    } else {
      dispatch(setIsCompletedFilled(false));
    }
  }, [account.account_env, availability, aliasLimit]);

  return (
    <div className="creation-step-three">
      <h3>Select the account type you want to request (3/6)</h3>
      {
        accountTypes.map((type) => (
          <RadioButton
            key={type.name}
            inputId={type.name}
            className="creation-step-three__checkbox"
            name={type.name}
            required
            value={account.account_env}
            checked={account.account_env === type.value}
            disabled={!type.active}
            onChange={() => {
              dispatch(editAccount({ ...account, account_env: type.value }));
              filterAccountName(type.value);
            }}
          >
          &nbsp;&nbsp;
            {type.label}
          </RadioButton>
        ))
      }
      <div>
        &nbsp;
        <h5>Expected Account-Name:</h5>
        { account.account_env && (
          <div>
            <h5 className="expected-account-name">
              {account.account_brand}
              -
              {account.account_env}
              -
              {account.account_project}
              -
              {account.account_spec}
            </h5>
            {!availability ? (<span style={{ color: '#cc0033' }}>Account Name not available</span>) : ''}
          </div>
        )}
      </div>
      <div>
        &nbsp;
        <h5>Account Alias:</h5>
        { account.account_env && (
          <div>
            <h5 className="expected-account-name">
              {account.account_brand}
              -
              {account.account_env}
              -
              {account.project_name}
              -
              {account.account_spec}
              -project-account
            </h5>
            {aliasLimit ? (<span style={{ color: '#cc0033' }}>Account Alias should be shorter than 64 characters.</span>) : ''}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreationStepThree;
