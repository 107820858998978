import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import './Header.scss';
import { NavigationBar, NavigationItem } from '@audi/audi-ui-react';

interface RootState {
  user: {
    userRole: string;
  }
}

const Header = () => {
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const [state, setState] = useState('nav-item-1__basic');

  return (
    <div className="header">
      <NavigationBar id="nav-bar__basic" selected={state} onSelect={setState}>
        <NavigationItem id="nav-item-1__basic">ACCOUNT MANAGEMENT</NavigationItem>
        {userRole === 'admin' && <NavigationItem id="nav-item-2__basic">REPORTS</NavigationItem>}
        {userRole === 'admin' && <NavigationItem id="nav-item-3__basic">ADMIN</NavigationItem>}
      </NavigationBar>
    </div>
  );
};

export default Header;
