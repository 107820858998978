import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './AccountCreationSteps.scss';
import { accountDetailsFields, accountsAndPermissionsFirst } from '../../helpers/inputFields';
import MultiInputFields from '../shared/MultiInputFields';
import { setIsCompletedFilled } from '../../app/slices/accountCreationStepsSlice';

interface RootState {
  accounts: {
    account: Account
  }
}

interface Account {
 [key: string]: string & Contact;
}

interface Contact {
  email: '',
}

const CreationStepFour = () => {
  const dispatch = useDispatch();
  const account = useSelector((state: RootState) => state.accounts.account);
  const stepFourFields = ['cost_center', 'cost_psp_element', 'cost_assignment', 'cost_center_wa', 'budget_limit_alert', 'leanix_id', 'security_contact', 'operations_contact', 'requestor_contact'];
  const mandatoryFields = ['budget_limit_alert', 'security_contact', 'operations_contact', 'requestor_contact'];
  const newChecks = mandatoryFields.map((item) => account[item]);
  const filteredFieldsMiddlePane = accountDetailsFields.filter(
    (item) => stepFourFields.indexOf(item.name) > -1,
  );
  const filteredFieldsRightPane = accountsAndPermissionsFirst.filter(
    (item) => stepFourFields.indexOf(item.name) > -1,
  );

  // To make all fields editable in step four
  filteredFieldsMiddlePane.forEach((element) => {
    element.readOnly = false;
  });
  filteredFieldsRightPane.forEach((element) => {
    element.children.forEach((children) => {
      children.readOnly = false;
    });
  });

  const filteredFields = [...filteredFieldsMiddlePane, ...filteredFieldsRightPane];

  const checkValidateALlFields = () => {
    for (let i = 0; i < newChecks.length; i++) {
      const isEmpty = Object.values(newChecks[i]).some((x) => x === '');
      if ((newChecks[i] === '') || isEmpty) { return dispatch(setIsCompletedFilled(false)); }
    }
    dispatch(setIsCompletedFilled(true));
  };

  useEffect(() => {
    checkValidateALlFields();
  }, [account]);

  return (
    <div className="creation-step-four">
      <h3>Provide billing and organization information for your project (4/6)</h3>
      <div className="creation-step-four__scroll-container">
        {filteredFields.map((item) => (
          <MultiInputFields item={item} key={item.name} />
        ))}
      </div>
    </div>
  );
};

export default CreationStepFour;
