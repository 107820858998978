import React, { useEffect, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import '../AccountDetails.scss';
import {
  mandatoryFields, Rootstate, Alert, PreviousAccount,
} from '../AccountDetailsHelpers';
import { accountDetailsFields } from '../../../helpers/inputFields';
import { axiosInstance } from '../../../axios';
import MultiInputFields from '../../shared/MultiInputFields';
import { editAccount, fetchActiveAccounts, fetchAccountsToBeDeleted } from '../../../app/slices/accountListSlice';
import DataProtectionFields from '../DataProtectionFields';
import SpeedDial from '../../shared/SpeedDial';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { accountState } from '../../../helpers/objects';

interface Props {
  handleAlert: ({ open, type }: Alert) => void;
}

const ActiveAccount: FC<Props> = ({ handleAlert }) => {
  const dispatch = useDispatch();
  const userRole = useSelector((state: Rootstate) => state.user.userRole);
  const account = useSelector((state: Rootstate) => state.accounts.account);
  const selectedAccountId = useSelector((state: Rootstate) => state.accounts.selectedAccount);
  const [actionType, setActionType] = useState('');
  const [prevAccount, setPreviousAccount] = useState<PreviousAccount | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [allFilled, setAllFilled] = useState(true);
  const mandatoryChecks = mandatoryFields.map((item) => account[item]);
  const [pendingDeletion, setPendingDeletion] = useState(false);
  const isValidated = useSelector((state: Rootstate) => state.step.isValidated);

  const checkValidateALlFields = () => {
    for (let i = 0; i < mandatoryChecks.length; i++) {
      let isEmpty;
      // checks if the field is undefined to prevent errors in Object.values function
      if (typeof mandatoryChecks[i] === 'undefined') {
        isEmpty = true;
      } else {
        isEmpty = Object.values(mandatoryChecks[i]).some((x) => x === '');
      }
      if ((mandatoryChecks[i] === '') || isEmpty) { return setAllFilled(false); }
    }
    setAllFilled(true);
  };

  // disable or enable save button
  // ADMIN can ALWAYS save
  useEffect(() => {
    if (((JSON.stringify(prevAccount) !== JSON.stringify(account))
      && !pendingDeletion && isValidated && allFilled) || userRole === 'admin') {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [account, pendingDeletion, allFilled]);

  useEffect(() => {
    checkValidateALlFields();
  }, [account]);

  const fetchData = async () => {
    try {
      if (selectedAccountId) {
        dispatch(editAccount(accountState));
        const response = await axiosInstance().then((service) => service?.get(`/accounts/${selectedAccountId}`));
        const additional_owners = await axiosInstance().then((service) => service?.get(`/accounts/${selectedAccountId}/additional_owners`));
        const data = response?.data[0];
        Object.keys(accountState).map((key) => {
          if (data[key] === undefined) {
            data[key] = '';
          }
          return key;
        });
        const currentAccount = {
          cloud_provider: 'AWS',
          account_brand: data.brand,
          account_email: data.account_email,
          account_env: data.account_env,
          account_project: data.project,
          account_spec: data.account_spec,
          cost_center: data.cost_center,
          cost_psp_element: data.cost_psp_element,
          cost_assignment: data.cost_assignment || '8150000200',
          cost_center_wa: data.cost_center_wa,
          account_description: data.ext_info.comment,
          account_id: data.account_id,
          regions: data.allowed_regions,
          operations_contact: {
            full_name: data.contacts.operations.full_name,
            email: data.contacts.operations.email,
          },
          security_contact: {
            full_name: data.contacts.security.full_name,
            email: data.contacts.security.email,
          },
          requestor_contact: {
            full_name: data.ext_info.requestorFullName,
            email: data.ext_info.requestorEmailAddress,
          },
          account_name: data.account_name,
          project_name: data.project_name,
          budget_limit_alert: data.budget_limit_alert === '' ? '0,00' : data.budget_limit_alert,
          leanix_id: data.leanix_id,
          architect_assessment_date: data.architect_assessment_date,
          architect_assessment_performed: data.architect_assessment_performed,
          architect_fullname: data.architect_fullname,
          security_checkup_date: data.security_checkup_date,
          security_checkup_performed: data.security_checkup_performed,
          requestor_email: data.requestorEmailAddress,
          login_url: `https://${data.alias}.signin.aws.amazon.com/console`,
          welcome_mail_sent: data.welcome_mail_sent,
          pending_deletion: data.pending_deletion,
          additional_owners: additional_owners?.status !== 204 ? additional_owners?.data : [],
          new_additional_owner: {
            email: '',
            full_name: '',
          },
        };
        setPreviousAccount(
          currentAccount,
        );
        dispatch(editAccount(
          currentAccount,
        ));
        setPendingDeletion(data.pending_deletion);
      }
    } catch (error: any) {
      handleAlert({ open: true, type: 'error', message: error.message });
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedAccountId]);

  const openConfirmModal = (type: string) => {
    setOpenModal(true);
    setActionType(type);
  };

  const handleSubmit = async () => {
    if (actionType === 'save') {
      // admin can always save
      if ((allFilled || userRole === 'admin') && prevAccount) {
        try {
          const newObjBody: any = {};
          for (const [key, val] of Object.entries(prevAccount)) {
            for (const [key2, val2] of Object.entries(account)) {
              if (key === key2 && val
                !== val2) {
                newObjBody[key2] = val2;
              }
            }
          }

          await axiosInstance().then((service) => service?.patch(`/accounts/${selectedAccountId}`, newObjBody))
            .then((res) => {
              dispatch(fetchActiveAccounts());
              handleAlert({ open: true, type: 'success', message: 'Successfully saved the changes' });
              setOpenModal(false);
              setDisabledSave(true);
              fetchData();

              if (res?.status && res.status >= 400) {
                const errResponse = res?.data.message;
                handleAlert({ open: true, type: 'error', message: errResponse });
              }
            });
        } catch (error: any) {
          handleAlert({ open: true, type: 'error', message: error.message });
        }
      } else {
        handleAlert({ open: true, type: 'error', message: 'Please fill data into all required input fields' });
      }
    }
    if (actionType === 'delete') {
      const body = {
        action: 'suspend',
        account_id: account.account_id,
      };
      try {
        await axiosInstance().then((service) => service?.post('/requests', body))
          .then(() => {
            dispatch(fetchAccountsToBeDeleted());
            fetchData();
            handleAlert({ open: true, type: 'success', message: 'Successfully requested delete account' });
            setOpenModal(false);
          });
      } catch (error: any) {
        handleAlert({ open: true, type: 'error', message: error.message });
      }
    }
  };

  const actions = [
    {
      icon: <SaveRoundedIcon />, name: 'Save', disabled: disabledSave, handleClick: () => openConfirmModal('save'),
    },
    {
      icon: <DeleteForeverRoundedIcon />, name: 'Delete', disabled: pendingDeletion, handleClick: () => ('delete'),
    },
  ];

  const userReadOnly = [
    'account_name',
    'cost_center',
    'cost_psp_element',
    'cost_assignment',
    'account_project',
    'account_brand',
    'regions',
    'requestor_contact',
    'account_env',
  ];
  const adminReadOnly = [''];

  useEffect(() => {
    if (userRole === 'user') {
      accountDetailsFields.forEach((element) => {
        if (userReadOnly.includes(element.name)) {
          element.readOnly = true;
        }
      });
    } else if (userRole === 'admin') {
      accountDetailsFields.forEach((element) => {
        if (adminReadOnly.includes(element.name)) {
          element.readOnly = true;
        }
      });
    }
  }, [userRole]);

  return (
    <div className="account-details__form-group">
      <form action="">
        {accountDetailsFields.map((item) => (
          <div key={item.name}>
            {userRole === 'user' && item.userVisible === false ? '' : <MultiInputFields item={item} />}
          </div>
        ))}
        <DataProtectionFields accountListType="active" />
      </form>
      <div className="account-details__button-group">
        <SpeedDial actions={actions} />
      </div>
      <ConfirmModal
        type={actionType}
        name={account.account_name}
        show={openModal}
        handleClose={() => setOpenModal(false)}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default ActiveAccount;
