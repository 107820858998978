import React, { useEffect, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@audi/audi-ui-react';
import Grid from '@material-ui/core/Grid';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import ClearIcon from '@material-ui/icons/Clear';
import '../AccountDetails.scss';
import { accountToBeDeleted } from '../../../helpers/inputFields';
import { axiosInstance } from '../../../axios';
import {
  editAccount, fetchAccountsToBeDeleted, selectAccount, fetchActiveAccounts, fetchClosedAccounts,
} from '../../../app/slices/accountListSlice';
import SpeedDial from '../../shared/SpeedDial';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { accountState } from '../../../helpers/objects';
import { Rootstate, Alert } from '../AccountDetailsHelpers';

interface Props {
  handleAlert: ({ open, type }: Alert) => void;
}

interface AccountToBeDeleted {
 [key: string]: string,
}

const AccountToBeDeletd: FC<Props> = ({ handleAlert }) => {
  const dispatch = useDispatch();
  const selectedAccountId = useSelector((state: Rootstate) => state.accounts.selectedAccount);
  const [actionType, setActionType] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [accountId, setAccountId] = useState('');
  const [accountToDeleted, setAccountToDeleted] = useState<AccountToBeDeleted>({
    account_id: '',
    account_email: '',
    requestor_email: '',
    project_name: '',
  });

  const fetchData = async () => {
    try {
      if (selectedAccountId) {
        dispatch(editAccount(accountState));
        const response = await axiosInstance().then((service) => service?.get(`/requests/${selectedAccountId}`));
        const data = response?.data[0];
        Object.keys(accountState).map((key) => {
          if (data[key] === undefined) {
            data[key] = '';
          }
          return key;
        });
        const currentAccount = {
          account_id: data.account_id,
          account_email: data.account_email,
          requestor_email: data.requestor_email,
          project_name: data.project_name,
        };
        setAccountToDeleted(currentAccount);
        setAccountId(data.account_id);
      }
    } catch (error: any) {
      handleAlert({ open: true, type: 'error', message: error.message });
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedAccountId]);

  const openConfirmModal = (type: string) => {
    setOpenModal(true);
    setActionType(type);
  };

  const cancelDeletion = async () => {
    try {
      await axiosInstance().then((service) => service?.delete(`requests/${selectedAccountId}`))
        .then(() => {
          dispatch(fetchAccountsToBeDeleted());
          handleAlert({ open: true, type: 'success', message: 'Successfully canceled deleted account request' });
          setOpenModal(false);
          dispatch(selectAccount(''));
        });
    } catch (error: any) {
      handleAlert({ open: true, type: 'error', message: error.message });
    }
  };

  const handleSubmit = async () => {
    if (actionType === 'cancel') {
      cancelDeletion();
    }

    if (actionType === 'delete') {
      try {
        await axiosInstance().then((service) => service?.patch(`accounts/${accountId}`, { account_status: 'closed' }))
          .then(() => {
            handleAlert({ open: true, type: 'success', message: 'Successfully deleted account' });
            cancelDeletion();
            dispatch(fetchClosedAccounts());
            dispatch(fetchActiveAccounts());
          });
      } catch (error: any) {
        handleAlert({ open: true, type: 'error', message: error.message });
      }
    }
  };

  const actions = [
    {
      icon: <ClearIcon />, name: 'Cancel deletion', disabled: false, handleClick: () => openConfirmModal('cancel'),
    },
    {
      icon: <DeleteForeverRoundedIcon />, name: 'Confirm deletion', disabled: false, handleClick: () => openConfirmModal('delete'),
    },
  ];

  return (
    <div className="account-details__form-group">
      <form action="">
        {accountToBeDeleted.map((item) => (
          <div key={item.name}>
            <Grid container spacing={6}>
              <Grid item xs={4}>
                <label htmlFor={item.name}>
                  {item.label}
                </label>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  inputId="text-field__basic"
                  name={item.name}
                  type="text"
                  label=""
                  required={item.required}
                  value={accountToDeleted[item.name]}
                  readOnly={item.readOnly}
                />
              </Grid>
            </Grid>
          </div>
        ))}
      </form>
      <div className="account-details__button-group">
        <SpeedDial actions={actions} />
      </div>
      <ConfirmModal
        type={actionType}
        name={accountToDeleted.project_name}
        show={openModal}
        handleClose={() => setOpenModal(false)}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default AccountToBeDeletd;
