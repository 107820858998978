import React, { FC, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Checkbox, DatePicker, TextField,
} from '@audi/audi-ui-react';
import { DateValue } from '@audi/audi-ui-react/dist/types/components/DatePicker/DatePicker.types';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { editAccount } from '../../app/slices/accountListSlice';
import { dataClassificationOptions, grcRiskValueOptions } from '../../helpers/options';
import { setIsValidated } from '../../app/slices/accountCreationStepsSlice';
import PopOver from '../shared/PopOver';
import { Rootstate } from './AccountDetailsHelpers';

interface Props {
  accountListType: string;
}

interface RootState {
  accounts: {
    account: Account
  }
}

interface Account {
 [key: string]: string & boolean,
}

const DataProtectionFields:FC<Props> = ({ accountListType }) => {
  const dispatch = useDispatch();
  const userRole = useSelector((state: Rootstate) => state.user.userRole);
  const account = useSelector((state: RootState) => state.accounts.account);
  const regex = /^[a-zA-ZäöüÄÖÜß ,.'-]+$/;

  useEffect(() => {
    const elementExists = document.getElementById('text-field__basic__invalid-message');
    if (elementExists) {
      dispatch(setIsValidated(false));
    } else {
      dispatch(setIsValidated(true));
    }
  }, [account]);

  /*   const handleChangeSelect = (e: any) => {
    dispatch(editAccount({ ...account, [e.target.name]: e.target.value }));
  }; */

  const onChangeTextInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(editAccount({ ...account, [e.target.name]: e.target.value }));
  };

  const onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(editAccount({ ...account, [e.target.name]: e.target.checked }));
  };

  const onChangeAssessmentDate = (d: DateValue): void => {
    dispatch(editAccount({ ...account, architect_assessment_date: d?.toISOString() }));
  };

  const onChangeCheckupDate = (d: DateValue): void => {
    dispatch(editAccount({ ...account, security_checkup_date: d?.toISOString() }));
  };

  return (
    <div>
      <Grid container spacing={6}>
        <Grid item xs={4}>
          <label htmlFor="">
            Onboarding&nbsp;(
            <a
              href="https://group-wiki.wob.vw.vwg/wikis/x/dCz2VQ"
              target="_blank"
              rel="noreferrer"
            >
              info
            </a>
            )
            <PopOver
              description="Cloud OnBoarding as described"
            />
          </label>
        </Grid>
        <Grid item xs={7}>
          <Grid>
            <Checkbox
              inputId="checkbox-basic"
              className="creation-step-three__checkbox"
              required
              checked={account.architect_assessment_performed === undefined
                ? false : account.architect_assessment_performed}
              name="architect_assessment_performed"
              onChange={onChangeCheckBox}
              readOnly={userRole !== 'admin'}
            >
            &nbsp;&nbsp;Performed onboarding
            </Checkbox>
            <DatePicker
              inputId="text-field__basic"
              name="architect_assessment_date"
              id=""
              label=""
              hint="Date of Onboarding MM/DD/YYYY"
              required
              value={account.architect_assessment_date
                ? new Date(account.architect_assessment_date) : null}
              onChange={onChangeAssessmentDate}
              readOnly={userRole !== 'admin'}
            />
            <TextField
              invalid={account.architect_fullname && !regex.test(account.architect_fullname)}
              inputId="text-field__basic"
              name="architect_fullname"
              id=""
              type="text"
              label=""
              hint="Onboarder"
              required
              value={account.architect_fullname}
              onChange={onChangeTextInput}
              validationMessage="Incorrect entry"
              readOnly={userRole !== 'admin'}
            />
          </Grid>
        </Grid>
      </Grid>
      {accountListType !== 'pending' && (
      <Grid container spacing={6}>
        <Grid item xs={4}>
          <label htmlFor="">
            Security&nbsp;Checkup&nbsp;(
            <a
              href="https://volkswagen-net.de/wikis/x/roIdfg"
              target="_blank"
              rel="noreferrer"
            >
              info
            </a>
            )
            <PopOver
              description="Security Checkup as described"
            />
          </label>
        </Grid>
        <Grid item xs={7}>
          <Grid>
            <Checkbox
              inputId="checkbox-basic"
              className="creation-step-three__checkbox"
              required
              checked={account.security_checkup_performed === undefined
                ? false : account.security_checkup_performed}
              name="security_checkup_performed"
              onChange={onChangeCheckBox}
              readOnly={userRole !== 'admin'}
            >
            &nbsp;&nbsp;Performed security checkup
            </Checkbox>
            {account.security_checkup_performed && (
            <DatePicker
              inputId="text-field__basic"
              name="security_checkup_date"
              id=""
              label=""
              hint="Date of Security Checkup MM/DD/YYYY"
              required
              value={account.security_checkup_date
                ? new Date(account.security_checkup_date) : null}
              onChange={onChangeCheckupDate}
              readOnly={userRole !== 'admin'}
            />
            )}
          </Grid>
        </Grid>
      </Grid>
      )}
    </div>
  );
};

export default DataProtectionFields;
