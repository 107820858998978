import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  RadioButton, Checkbox, TextField,
} from '@audi/audi-ui-react';
import './AccountCreationSteps.scss';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { dataClassificationOptions, grcRiskValueOptions } from '../../helpers/options';
import { editAccount } from '../../app/slices/accountListSlice';
import { setIsValidated, setIsCompletedFilled } from '../../app/slices/accountCreationStepsSlice';
import PopOver from '../shared/PopOver';

interface RootState {
  accounts: {
    account: Account & {[key: string]: boolean},
  }
}

interface Account {
 [key: string]: string,
}

const CreationStepFive = () => {
  const dispatch = useDispatch();
  const account = useSelector((state: RootState) => state.accounts.account);
  const regex = /^[a-zA-ZäöüÄÖÜß ,.'-]+$/;

  const validateValue = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (regex) {
      if (e.target.value !== '') {
        const validatedValue = regex.test(e.target.value);
        dispatch(setIsValidated(validatedValue));
      } else {
        dispatch(setIsValidated(true));
      }
    }
  };

  const onChangeTextInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    validateValue(e);
    dispatch(editAccount({ ...account, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    dispatch(setIsCompletedFilled(true));
  });

  return (
    <div className="creation-step-five">
      <h3>
        Cloud Onboarding (5/6)
      </h3>

      <div className="creation-step-five__section">
        <p>
          The cloud onboarding process advises project initiatives on the possibility of cloud use.
          It describes the process, summarizes the extensive requirements from other OEs, and
          provides information about how to set up a cloud environment.
          The specific goals of Cloud Onboarding are:
          <ul>
            <li>
              Communicating a holistic understanding of the cloud lifecycle
              and raising awareness of group guidelines for cloud initiatives
            </li>
            <li>
              Initial consultation of project initiatives and handing out of
              auxiliary materials (mynet, process overview, roadmap)
            </li>
            <li>
              Provision of templates and instructions for use of
              process-relevant documents for self-filling
            </li>
            <li>
              To communicate the initiatives to cloud consultants from the Audi
              Cloud Service (ACS) team to discuss downstream technical issues
            </li>
          </ul>
          See
          <a
            href="https://group-wiki.wob.vw.vwg/wikis/display/AudiCLOUD/Cloud+Onboarding+Process"
            target="_blank"
            rel="noopener noreferrer"
          >
            &nbsp;more
          </a>
        </p>
      </div>
    </div>
  );
};

export default CreationStepFive;
