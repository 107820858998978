import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './AccountStatus.scss';
import '../AccountAddInfos.scss';
import { accountRootStatus } from '../../../helpers/options';
import { Rootstate } from '../../AccountDetails/AccountDetailsHelpers';
import { fetchActiveAccountStatus, fetchPendingAccountStatus, resetAccountStatusCards } from '../../../app/slices/accountListSlice';

function updateStatus(rootStatus: any, newStatus: any) {
  rootStatus.forEach((stat: string[], index: number) => {
    for (const key in stat) {
      // stat: is on status object
      // key: are the keys in that status object
      // stat[key]: is the value of current key in the status object
      // console.log(newStatus[index].hasOwnProperty(key) ? newStatus[index][key] : stat[key]);
      if (key in newStatus[index]) {
        stat[key] = newStatus[index][key];
      }
    }
  });
  return rootStatus;
}

const AccountStatus = () => {
  const dispatch = useDispatch();
  const selectedAccountId = useSelector((state: Rootstate) => state.accounts.selectedAccount);
  const directory = useSelector((state: Rootstate) => state.accounts.directory);
  const accountStatusState = useSelector((state: Rootstate) => state.accounts.status);

  useEffect(() => {
    if (selectedAccountId) {
      if (directory === 'active account') {
        dispatch(fetchActiveAccountStatus(selectedAccountId));
      }
      if (directory === 'pending account') {
        dispatch(fetchPendingAccountStatus(selectedAccountId));
      }
      if (directory === 'closedAccount') {
        dispatch(resetAccountStatusCards(accountRootStatus));
      }
    }
  }, [selectedAccountId, directory]);

  // const latestAccountStatus = updateStatus(accountStatus, accountStatusState);
  const title = 'Account Status';
  const [visibility, setVisibility] = useState(false);

  const toggleContent = () => {
    setVisibility(!visibility);
  };

  return (
    <div>
      <button
        type="button"
        className={`add-info__sub-header collapsible ${visibility ? 'active' : ''}`}
        onClick={toggleContent}
      >
        { title }
      </button>
      {visibility && (
      <div className="content">
        {
          !selectedAccountId ? (
            <div style={{ padding: '0.4rem' }}>Please select an Account for further details</div>
          ) : (
            <div className="status-container">
              { accountStatusState.map((accStatus: any) => (
                <div className={`status-card ${accStatus.progress}`}>
                  <h5>{accStatus.statusLabel}</h5>
                  <div className="marker" />
                  <div className="icon" />
                  <p>{accStatus.statusDesc}</p>
                  <a
                    href={accStatus.statusDocuUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    More Information
                  </a>
                </div>
              ))}
            </div>
          )
        }
      </div>
      )}
    </div>
  );
};

export default AccountStatus;
