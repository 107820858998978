import React, { useState } from 'react';
import { RadioButton } from '@audi/audi-ui-react';
import './AccountCreationSteps.scss';

const CreationStepOne = () => {
  const [awsAccount] = useState(true);
  const [azureSubscription] = useState(false);
  return (
    <div className="creation-step-one">
      <h3>
        Select Cloud Provider (1/6)
      </h3>
      <RadioButton inputId="radio-button-basic" checked={awsAccount} className="creation-step-one__radio-button">
        &nbsp;AWS Account
      </RadioButton>
      <RadioButton inputId="radio-button-basic" checked={azureSubscription} className="creation-step-one__radio-button" disabled>
        &nbsp;Azure Subscription
      </RadioButton>
    </div>
  );
};

export default CreationStepOne;
