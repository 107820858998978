import React, { useEffect } from 'react';
import { useReactOidc } from '@axa-fr/react-oidc-context';
import { useDispatch } from 'react-redux';
import Header from '../../components/Header/Header';
import AccountManagement from '../AccountManagement/AccountManagement';
import { updateUserRole } from '../../app/slices/userSlice';

function MainPage() {
  const dispatch = useDispatch();
  const { oidcUser } = useReactOidc();
  // retrive defined admin emails and groups from config file
  const adminEmails = process.env.REACT_APP_ADMIN_EMAILS?.replace(/\s/g, '').split(',');
  const adminGroups = process.env.REACT_APP_ADMIN_GROUPS?.replace(/\s/g, '').split(',');

  // fetch email and groups from OIDC-User
  let userEmail = oidcUser?.profile.email;
  userEmail = userEmail?.toString().toLowerCase();
  let xGroup = oidcUser?.profile['x-groups'];
  xGroup = xGroup ? xGroup.split(',') : '';

  // when env is local (for development) overwrite the userEmail and xGroup
  if (process.env.REACT_APP_ENV === 'local') {
    const localUserEmail = process.env.REACT_APP_USER_EMAIL?.toString().toLowerCase();
    const localUserGroups = process.env.REACT_APP_USER_GROUPS?.replace(/\s/g, '').split(',');
    userEmail = localUserEmail;
    xGroup = localUserGroups;
  }

  useEffect(() => {
    let role = 'user';
    for (const group of xGroup) {
      if (adminGroups?.includes(group)) {
        role = 'admin';
      }
    }

    if (userEmail) {
      if (adminEmails?.includes(userEmail)) {
        role = 'admin';
      }
    }
    dispatch(updateUserRole(role));
  }, []);
  return (
    <div className="App">
      <Header />
      <div style={{ paddingTop: '16px' }}>
        <AccountManagement />
      </div>
    </div>
  );
}

export default MainPage;
