import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import './CreateAccountModal.scss';
import { Button } from '@audi/audi-ui-react';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { handleNextStep, handlePreviousStep, handleResetStep } from '../../app/slices/accountCreationStepsSlice';
import { editAccount, fetchPendingAccounts } from '../../app/slices/accountListSlice';
import { accountState } from '../../helpers/objects';
import { axiosInstance } from '../../axios';
import { Rootstate } from '../AccountDetails/AccountDetailsHelpers';

interface Props {
  show: boolean;
  handleClose: () => void;
  handleAlert: ({ open, type }: Alert) => void;
  children: React.ReactNode;
}

interface Alert {
  open: boolean;
  type: string;
  message?: string;
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const CreateAccountModal: FC<Props> = ({
  show, handleClose, children, handleAlert,
}) => {
  const dispatch = useDispatch();
  const step = useSelector((state: Rootstate) => state.step.step);
  const isValidated = useSelector((state: Rootstate) => state.step.isValidated);
  const isCompletedFilled = useSelector((state: Rootstate) => state.step.isCompletedFilled);
  const account = useSelector((state: Rootstate) => state.accounts.account);

  const handleCancleCreation = () => {
    handleClose();
    dispatch(handleResetStep());
    dispatch(editAccount(accountState));
  };

  const postProcessChecks = () => {
    if (account.cost_center === '' && account.cost_psp_element === undefined) {
      return 'Cost Center or Cost PSP Element must be set';
    }
    if (account.cost_center.length > 0 && account.cost_psp_element.length > 0) {
      return 'Cost Center and Cost PSP Element cannot be set at the same time';
    }
    return true;
  };

  const handleSubmitRequest = () => {
    if (postProcessChecks() === true) {
      const accountCopy = { ...account };
      delete accountCopy.additional_owners;
      delete accountCopy.new_additional_owner;
      axiosInstance().then((service) => service?.post('requests', accountCopy))
        .then((res) => {
          handleAlert({ open: true, type: 'success' });
          handleClose();
          dispatch(handleResetStep());
          dispatch(editAccount(accountState));
          dispatch(fetchPendingAccounts());
          if (res?.status && res.status >= 400) {
            const errResponse = res?.data.message;
            handleAlert({ open: true, type: 'error', message: errResponse });
          }
        })
        .catch((error) => { handleAlert({ open: true, type: 'error', message: error.message }); });
    } else {
      handleAlert({ open: true, type: 'error', message: postProcessChecks().toString() });
    }
  };

  const disableNext = () => {
    if (step === 2 || step === 4 || step === 5) {
      if (!isCompletedFilled || !isValidated) {
        return true;
      }
      return false;
    }
    if (step === 3 || step === 6) {
      if (!isCompletedFilled) {
        return true;
      }
      return false;
    }
    return false;
  };

  return (
    <div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Create Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="small" onClick={handleCancleCreation}>
            Cancel
          </Button>
          {step > 1 && (
          <Button variant="primary" size="small" onClick={() => dispatch(handlePreviousStep())}>
            Back
          </Button>
          )}
          {step < 6 && (
          <Button variant="primary" size="small" disabled={disableNext()} onClick={() => dispatch(handleNextStep())}>
            Next
          </Button>
          )}
          {step === 6 && (
          <Button variant="primary" size="small" disabled={disableNext()} onClick={handleSubmitRequest}>
            Request
          </Button>
          )}

        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateAccountModal;
