export const accountState = {
  action: 'create',
  account_brand: '',
  account_env: '',
  account_project: '',
  account_spec: '',
  cost_center: '',
  cost_center_wa: '',
  cost_psp_element: '',
  cost_assignment: '8150000200',
  account_description: '',
  regions: ['eu-central-1', 'eu-west-1', 'us-east-1'],
  operations_contact: {
    full_name: '',
    email: '',
  },
  security_contact: {
    full_name: '',
    email: '',
  },
  requestor_contact: {
    full_name: '',
    email: '',
  },
  requestor_email: '',
  account_name: '',
  project_name: '',
  budget_limit_alert: '0,00',
  leanix_id: '',
  architect_assessment_date: null,
  architect_assessment_performed: false,
  architect_fullname: '',
  security_checkup_date: null,
  security_checkup_performed: false,
  welcome_mail_sent: false,
  additional_owners: [],
  new_additional_owner: {
    full_name: '',
    email: '',
  },
};

export const projectState = {
  project_name: '',
  project_id: '',
  project_description: '',
  project_owner: {
    full_name: '',
    email: '',
    department: '',
  },
  project_finops_contact: {
    full_name: '',
    email: '',
  },
  project_cost_center: '',
  project_cost_psp_element: '',
  project_cost_assignment: '',
  project_budget_limit_alert: '0,00',
  project_accounts: [
    {
      name: '',
      id: '',
    },
  ],
  project_status: 'active',
};
