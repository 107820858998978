/* eslint-disable max-len */
import React from 'react';

// import { GroupuiCard, GroupuiHeadline, GroupuiIcon, GroupuiLink } from '@group-ui/group-ui-react';
import {} from '@audi/audi-ui-react';

import logo from '../../../assets/images/audi.svg';
import './Imprint.scss';

const Imprint: React.FC = (props) => (
  <>
    <div className="imprint">
      <div className="headline-container">
        <h1>Legal notice</h1>
        <img className="logo" src={logo} alt="logo" />
      </div>
      <br />
      <h4>AUDI AG</h4>
      <br />
      <b>Mailing Address</b>
      <br />
      AUDI AG
      <br />
      Auto-Union-Str. 1
      <br />
      85057 Ingolstadt
      <br />
      Deutschland
      <br />
      <br />
      <b>Phone:</b>
      <br />
      +49 (0)841 89-0
      <br />
      <br />
      <b>Fax:</b>
      <br />
      +49 (0)841 89-32524
      <br />
      <br />
      <b>E-mail:</b>
      <br />
      <a href="mailto:imprint@audi.de">imprint@audi.de</a>
      <br />
      <br />
      AUDI AG is a stock corporation under German law, the headoffice is located in Ingolstadt.
      <br />
      <br />
      <b>Chairman of the Supervisory Board:</b>
      <br />
      Manfred Döss
      <br />
      <br />
      <b>Board of Management </b>
      <br />
      Gernot Döllner (Chairman of the Board of Management)
      <br />
      Jürgen Rittersberger
      <br />
      Javier Ros Hernández
      <br />
      Renate Vachenauer
      <br />
      Gerd Walker
      <br />
      Hildegard Wortmann
      <p>&nbsp;</p>
      AUDI AG is registered in the commercial register of the Amtsgericht (local court) Ingolstadt under the number HR B
      1.
      <br />
      <br />
      The VAT registration number of AUDI AG is DE811115368.
      <br />
      <br />
      <br />
      The European Commission has set up an online dispute resolution platform (ODR platform) for settling out of court
      on consumer rights disputes regarding contractual obligations that arise from online shopping contracts. The
      platform is available at&nbsp;
      <a href="http://ec.europa.eu/consumers/odr" target="blank">
        http://ec.europa.eu/consumers/odr
      </a>
      <br />
      <br />
      AUDI AG does not commit to nor is it obliged to participate in an alternative dispute resolution process handled
      by a consumer dispute resolution body.
    </div>
  </>
);

export default Imprint;
