import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { OidcSecure } from '@axa-fr/react-oidc-context';
import MainPage from '../pages/MainPage/MainPage';
import IntroPage from '../pages/IntroPage/IntroPage';
import Imprint from '../pages/Legal/Imprint/Imprint';

const PageNotFound = () => <div>Page not found</div>;

const Routes = () => (
  <Switch>
    <Route exact path="/">
      <h1>Home</h1>
    </Route>
    <Route exact path="/intro">
      <IntroPage />
    </Route>
    <Route path="/main">
      <OidcSecure>
        <MainPage />
      </OidcSecure>
    </Route>
    <Route exact path="/legal/imprint">
      <Imprint />
    </Route>
    <Route component={PageNotFound} />
  </Switch>
);

export default Routes;
