import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextArea, TextField } from '@audi/audi-ui-react';
import Grid from '@material-ui/core/Grid';
import '../AccountCreationSteps/AccountCreationSteps.scss';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import { editAccount, editProject } from '../../app/slices/accountListSlice';
import { setIsValidated, setUpdatingAccount } from '../../app/slices/accountCreationStepsSlice';
import PopOver from './PopOver';

interface Props {
  item: InputField
  isProjectField?: boolean
  aoDelete?: (ao_email: string, ao_full_name: string) => Promise<void>
}

interface InputField {
  name: string;
  type: string;
  required: boolean;
  label: string;
  children?: ChildrenField[];
  options?: {label: string, value: string}[] | undefined;
  readOnly?: boolean;
  regex?: RegExp;
  description?: string;
}

interface ChildrenField {
    name: string,
    type: string;
    required: boolean;
    label: string;
    id: string;
    regex?: RegExp;
    readOnly?: boolean;
}

interface RootState {
  accounts: {
    account: Account & Contact;
    project: Project & Contact;
    directory: string;
  }
}

interface Contact {
  [key: string]: {
    [key: string]: string,
  }
}

interface Account {
 [key: string]: string & { email: string, full_name: string }[] & string[],
}

interface Project {
  [key: string]:
    string & {
      account_env: string,
      account_id: string,
      account_label: string,
      account_status: string
    }[] & string[],
 }

const MultiInputFields:FC<Props> = ({ item, isProjectField, aoDelete }) => {
  const dispatch = useDispatch();
  const account = useSelector((state: RootState) => state.accounts.account);
  const project = useSelector((state: RootState) => state.accounts.project);
  const directory = useSelector((state: RootState) => state.accounts.directory);

  const validateValue = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    regex?: RegExp,
  ) => {
    if (regex) {
      const validatedValue = regex.test(e.target.value);
      dispatch(setIsValidated(validatedValue));
    }
  };

  const handleChangeSelect = (e: any) => {
    if (isProjectField) {
      dispatch(editProject({ ...project, [e.target.name]: e.target.value }));
    } else {
      dispatch(editAccount({ ...account, [e.target.name]: e.target.value }));
    }
    dispatch(editAccount({ ...account, [e.target.name]: e.target.value }));
    dispatch(setUpdatingAccount(true));
  };

  const onChangeTextInput = (e: React.ChangeEvent<HTMLInputElement>, regex?: RegExp): void => {
    validateValue(e, regex);
    if (isProjectField) {
      dispatch(editProject({ ...project, [e.target.name]: e.target.value }));
    } else {
      dispatch(editAccount({ ...account, [e.target.name]: e.target.value }));
    }
    dispatch(setUpdatingAccount(true));
  };

  const onChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>, regex?: RegExp): void => {
    validateValue(e, regex);
    if (isProjectField) {
      dispatch(editProject({ ...project, [e.target.name]: e.target.value }));
    } else {
      dispatch(editAccount({ ...account, [e.target.name]: e.target.value }));
    }
    dispatch(setUpdatingAccount(true));
  };

  const onChangeChildInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    parent: string,
  ): void => {
    dispatch(editAccount({
      ...account,
      [parent]: { ...account[parent] as object, [e.target.name]: e.target.value },
    }));
    dispatch(setUpdatingAccount(true));
  };

  const onChangeChildInputProject = (
    e: React.ChangeEvent<HTMLInputElement>,
    parent: string,
  ): void => {
    dispatch(editProject({
      ...project,
      [parent]: { ...project[parent] as object, [e.target.name]: e.target.value },
    }));
  };

  useEffect(() => {
    const elementExists = document.getElementById('text-field__basic__invalid-message');
    if (elementExists) {
      dispatch(setIsValidated(false));
    } else {
      dispatch(setIsValidated(true));
    }
  }, [account]);

  useEffect(() => {
    dispatch(editAccount({ ...account, requestor_email: account.requestor_contact.email }));
  }, [account.requestor_contact.email]);

  const renderChildFields = (children: ChildrenField[] | undefined, parent: string) => (
    <>
      {
      children?.map((child: ChildrenField) => {
        const validatedValue = child.regex?.test(account[parent][child.name]);
        return (
          <TextField
            key={child.name}
            invalid={(account[parent][child.name] && child.regex) ? !validatedValue : false}
            inputId="text-field__basic"
            name={child.name}
            type="text"
            label=""
            hint={child.label}
            required
            value={account[parent][child.name]}
            readOnly={directory === 'closedAccount' ? true : child.readOnly}
            onChange={
            (e: React.ChangeEvent<HTMLInputElement>) => onChangeChildInput(e, parent)
        }
            validationMessage="Incorrect entry."
          />

        );
      })
      }
    </>
  );

  const renderChildFieldsProject = (children: ChildrenField[] | undefined, parent: string) => (
    <>
      {
      children?.map((child: ChildrenField) => {
        const validatedValue = child.regex?.test(project[parent][child.name]);
        return (
          <TextField
            key={child.name}
            invalid={(project[parent][child.name] && child.regex) ? !validatedValue : false}
            inputId="text-field__basic"
            name={child.name}
            type="text"
            label=""
            hint={child.label}
            required
            value={project[parent][child.name]}
            readOnly={item.readOnly}
            onChange={
            (e: React.ChangeEvent<HTMLInputElement>) => onChangeChildInputProject(e, parent)
        }
            validationMessage="Incorrect entry."
          />

        );
      })
      }
    </>
  );

  switch (item.type) {
    case 'text':
      return (
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <label htmlFor={item.name}>
              {item.label}
              {item.description && <PopOver description={item.description} />}
              {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
            </label>
          </Grid>
          <Grid item xs={7}>
            <TextField
              invalid={
                (account[item.name] && item.regex)
                  ? !item.regex?.test(account[item.name])
                  : false
}
              inputId="text-field__basic"
              name={item.name}
              type="text"
              label=""
              required
              value={account[item.name]}
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) => onChangeTextInput(e, item.regex)
}
              validationMessage="Incorrect entry"
              readOnly={directory === 'closedAccount' ? true : item.readOnly}
            />
          </Grid>
        </Grid>
      );
      break;
    case 'textArea':
      return (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <label htmlFor={item.name}>
              {item.label}
              <PopOver description={item.description} />
              {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
            </label>
          </Grid>
          <Grid item xs={7}>
            <TextArea
              inputId="text-area-basic"
              label=""
              name={item.name}
              disabled={item.readOnly}
              id={item.name}
              required={item.required}
              value={account[item.name]}
              onChange={
                (e: React.ChangeEvent<HTMLTextAreaElement>) => onChangeTextArea(e, item.regex)
}
              invalid={account[item.name] ? !item.regex?.test(account[item.name]) : false}
              validationMessage="Incorrect entry"
            />
          </Grid>
        </Grid>
      );
    case 'map':
      return (
        <div className="creation-step-four__form-group">
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <label htmlFor={item.name}>
                {item.label}
                <PopOver description={item.description} />
                {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
              </label>
            </Grid>
            <Grid item xs={7}>
              {renderChildFields(item.children, item.name)}
            </Grid>
          </Grid>
        </div>
      );
    case 'select':
      return (
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <label htmlFor="">
              {item.label}
              {' '}
              <PopOver description={item.description} />
              {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
            </label>
          </Grid>
          <Grid item xs={7}>
            <InputLabel id="demo-simple-select-label">{item.label}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name={item.name}
              value={account[item.name]}
              onChange={handleChangeSelect}
              readOnly={directory === 'closedAccount' ? true : item.readOnly}
            >
              {
                item?.options?.map((option) => (
                  <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
                ))
              }
            </Select>
          </Grid>
        </Grid>
      );
    case 'multiSelect':
      return (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <label htmlFor={item.name}>
              {item.label}
              <PopOver description={item.description} />
              {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
            </label>
          </Grid>
          <Grid item xs={7}>
            <InputLabel id="demo-mutiple-checkbox-label">{item.name}</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              name={item.name}
              value={account[item.name]}
              onChange={handleChangeSelect}
              input={<Input />}
              renderValue={(selected: any) => selected.join(', ')}
              readOnly={directory === 'closedAccount' ? true : item.readOnly}
            >
              {item?.options?.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                  <Checkbox checked={account[item.name].indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      );
    case 'textProject':
      return (
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <label htmlFor={item.name}>
              {item.label}
              {item.description && <PopOver description={item.description} />}
              {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
            </label>
          </Grid>
          <Grid item xs={7}>
            <TextField
              invalid={
                (project[item.name] && item.regex)
                  ? !item.regex?.test(project[item.name])
                  : false
  }
              inputId="text-field__basic"
              name={item.name}
              type="text"
              label=""
              required
              value={project[item.name]}
              onChange={
                (e: React.ChangeEvent<HTMLInputElement>) => onChangeTextInput(e, item.regex)
  }
              validationMessage="Incorrect entry"
              readOnly={directory === 'closedAccount' ? true : item.readOnly}
            />
          </Grid>
        </Grid>
      );
      break;
    case 'textAreaProject':
      return (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <label htmlFor={item.name}>
              {item.label}
              <PopOver description={item.description} />
              {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
            </label>
          </Grid>
          <Grid item xs={7}>
            <TextArea
              inputId="text-area-basic"
              label=""
              name={item.name}
              disabled={item.readOnly}
              id={item.name}
              required={item.required}
              value={project[item.name]}
              onChange={
                (e: React.ChangeEvent<HTMLTextAreaElement>) => onChangeTextArea(e, item.regex)
  }
            />
          </Grid>
        </Grid>
      );
    case 'mapProject':
      return (
        <div className="creation-step-four__form-group">
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <label htmlFor={item.name}>
                {item.label}
                <PopOver description={item.description} />
                {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
              </label>
            </Grid>
            <Grid item xs={7}>
              {renderChildFieldsProject(item.children, item.name)}
            </Grid>
          </Grid>
        </div>
      );
    case 'selectProject':
      return (
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <label htmlFor="">
              {item.label}
              {' '}
              <PopOver description={item.description} />
              {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
            </label>
          </Grid>
          <Grid item xs={7}>
            <InputLabel id="demo-simple-select-label">{item.label}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name={item.name}
              value={project[item.name]}
              onChange={handleChangeSelect}
              readOnly={item.readOnly}
            >
              {
                item?.options?.map((option) => (
                  <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>
                ))
              }
            </Select>
          </Grid>
        </Grid>
      );
    case 'multiSelectProject':
      return (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <label htmlFor={item.name}>
              {item.label}
              <PopOver description={item.description} />
              {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
            </label>
          </Grid>
          <Grid item xs={7}>
            <InputLabel id="demo-mutiple-checkbox-label">{item.name}</InputLabel>
            <Select
              labelId="demo-mutiple-checkbox-label"
              id="demo-mutiple-checkbox"
              multiple
              name={item.name}
              value={project[item.name]}
              onChange={handleChangeSelect}
              input={<Input />}
              renderValue={(selected: any) => selected.join(', ')}
              readOnly={item.readOnly}
            >
              {item?.options?.map((option) => (
                <MenuItem key={option.label} value={option.value}>
                  <Checkbox checked={project[item.name].indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      );
    case 'listProject':
      return (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <label htmlFor={item.name}>
              {item.label}
              <PopOver description={item.description} />
              {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
            </label>
          </Grid>
          <Grid item xs={7}>
            <InputLabel id="demo-mutiple-checkbox-label">{item.name}</InputLabel>
            <List>
              {project[item.name].map((listItem) => (
                <ListItem>
                  <ListItemText primary={listItem.account_label} secondary={listItem.account_id} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      );
    case 'listAdditionalOwners':
      return (
        <Grid container spacing={5}>
          <Grid item xs={4}>
            <label htmlFor={item.name}>
              {item.label}
              <PopOver description={item.description} />
              {item.required && <span style={{ color: 'red' }}>&nbsp;&nbsp;**</span>}
            </label>
          </Grid>
          <Grid item xs={7}>
            <List>
              {account[item.name] !== undefined ? account[item.name].map((listItem) => (
                <ListItem>
                  <ListItemText primary={listItem.full_name} secondary={listItem.email} />
                  {aoDelete ? (
                    <DeleteForeverRoundedIcon
                      onClick={() => aoDelete(listItem.email, listItem.full_name)}
                    />
                  ) : ''}
                </ListItem>
              )) : ''}
            </List>
          </Grid>
        </Grid>
      );
    default:
      return null;
  }
};

MultiInputFields.defaultProps = {
  isProjectField: false,
  aoDelete: async () => { console.log('No execution'); },
};

export default MultiInputFields;
