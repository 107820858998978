import React, { useState, useEffect } from 'react';
import { Checkbox } from '@audi/audi-ui-react';
import { useDispatch } from 'react-redux';
import './AccountCreationSteps.scss';
import { setIsCompletedFilled } from '../../app/slices/accountCreationStepsSlice';
import termOfUse from '../../assets/terms-of-use/terms_of_use.pdf';
import securityLeaflet from '../../assets/terms-of-use/AWS_Services_ITSecurity_Leaflet_V1.1.pdf';

const CreationStepSix = () => {
  const [privacyAgreement, setPrivacyAgreement] = useState(false);
  const [legalRequirement, setLegalRequirement] = useState(false);
  const [servicesGuidline, setServicesGuidline] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (privacyAgreement && legalRequirement && servicesGuidline) {
      dispatch(setIsCompletedFilled(true));
    } else {
      dispatch(setIsCompletedFilled(false));
    }
  }, [privacyAgreement, legalRequirement, servicesGuidline]);

  return (
    <div className="creation-step-six">
      <h3>
        Please read and agree to the privacy agreement
        in order to finalize the account request (6/6)
      </h3>

      <Checkbox
        inputId="termOfUse"
        className="creation-step-three__checkbox"
        name="termOfUse"
        required
        onChange={(e) => setPrivacyAgreement(e.target.checked)}
      >
          &nbsp;&nbsp;I have read, understand and agree to the
        {' '}
        <a href={termOfUse} target="_blank" rel="noreferrer">Term of Use</a>
      </Checkbox>
      <Checkbox
        inputId="legalRequirements"
        className="creation-step-three__checkbox"
        name="legalRequirements"
        required
        onChange={(e) => setLegalRequirement(e.target.checked)}
      >
        &nbsp;&nbsp;I have read, understand and will follow the advices from
        {' '}
        <a href={securityLeaflet} target="_blank" rel="noreferrer">IT Security Leaflet</a>
      </Checkbox>
      <Checkbox
        inputId="servicesGuidline"
        className="creation-step-three__checkbox"
        name="servicesGuidline"
        required
        onChange={(e) => setServicesGuidline(e.target.checked)}
      >
        &nbsp;&nbsp;I have read, understand and will follow
        {' '}
        <a target="_blank" href="https://group-wiki.wob.vw.vwg/wikis/display/AudiCLOUD/ACS+AWS+Foundation+Services+Responsibility+Guidelines+and+Security+Guardrails" rel="noreferrer">Services Responsibility</a>
        {' '}
        Guidelines
        and Account Inflation Boundaries
      </Checkbox>
    </div>
  );
};

export default CreationStepSix;
