import { combineReducers } from '@reduxjs/toolkit';
import accountListReducer from './slices/accountListSlice';
import accountCreationStepsReducer from './slices/accountCreationStepsSlice';
import indexReducer from './slices/userSlice';

const reducer = combineReducers({
  user: indexReducer,
  accounts: accountListReducer,
  step: accountCreationStepsReducer,
});

export default reducer;
