import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Button, TextArea } from '@audi/audi-ui-react';

interface Props {
  show: boolean;
  handleClose: () => void;
  handleSubmit: (x?: string) => void;
}

const RejectModal: FC<Props> = ({
  show, handleClose, handleSubmit,
}) => {
  const [reasonForReject, setReasonForReject] = useState('');

  useEffect(() => {
    setReasonForReject('');
  }, [show]);

  return (
    <div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body style={{ display: 'flex', alignItems: 'center', padding: '0 40px' }}>
          <div style={{
            fontSize: '24px', textAlign: 'center', width: '100%', marginTop: '40px',
          }}
          >
            <p>
              Please provide a reason for the rejection
            </p>
            <TextArea
              inputId="text-area-basic"
              label=""
              name="reason_for_rejection"
              id="reason_for_rejection"
              required
              value={reasonForReject}
              validationMessage="Incorrect entry"
              onChange={(e) => setReasonForReject(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" size="small" onClick={() => handleSubmit(reasonForReject)} disabled={!reasonForReject}>
            Reject
          </Button>
          <Button variant="secondary" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default RejectModal;
