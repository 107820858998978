export const regionsOptions = [
  { label: 'Asia Pacific (Singapore) - ap-southeast-1', value: 'ap-southeast-1' },
  { label: 'US West (Oregon) - us-west-2', value: 'us-west-2' },
  { label: 'EU (London) - eu-west-2', value: 'eu-west-2' },
  { label: 'EU (Stockholm) - eu-north-1', value: 'eu-north-1' },
  { label: 'EU (Paris) - eu-west-3', value: 'eu-west-3' },
];

export const allRegionsOptions = [
  { label: 'Asia Pacific (Hong Kong) - ap-east-1', value: 'ap-east-1' },
  { label: 'Asia Pacific (Mumbai) - ap-south-1', value: 'ap-south-1' },
  { label: 'Asia Pacific (Osaka-Local) - ap-northeast-3', value: 'ap-northeast-3' },
  { label: 'Asia Pacific (Seoul) - ap-northeast-2', value: 'ap-northeast-2' },
  { label: 'Asia Pacific (Singapore) - ap-southeast-1', value: 'ap-southeast-1' },
  { label: 'Asia Pacific (Sydney) - ap-southeast-2', value: 'ap-southeast-2' },
  { label: 'Asia Pacific (Tokyo) - ap-northeast-1', value: 'ap-northeast-1' },
  { label: 'Canada (Central) - ca-central-1', value: 'ca-central-1' },
  { label: 'China (Beijing) - cn-north-1', value: 'cn-north-1' },
  { label: 'China (Ningxia) - cn-northwest-1', value: 'cn-northwest-1' },
  { label: 'EU (Frankfurt) - eu-central-1', value: 'eu-central-1' },
  { label: 'EU (Ireland) - eu-west-1', value: 'eu-west-1' },
  { label: 'EU (London) - eu-west-2', value: 'eu-west-2' },
  { label: 'EU (Paris) - eu-west-3', value: 'eu-west-3' },
  { label: 'EU (Stockholm) - eu-north-1', value: 'eu-north-1' },
  { label: 'Middle East (Bahrain) - me-south-1', value: 'me-south-1' },
  { label: 'South America (Sao Paulo) - sa-east-1', value: 'sa-east-1' },
  { label: 'US East (N. Virginia) - us-east-1', value: 'us-east-1' },
  { label: 'US East (Ohio) - us-east-2', value: 'us-east-2' },
  { label: 'US West (N. California) - us-west-1', value: 'us-west-1' },
  { label: 'US West (Oregon) - us-west-2', value: 'us-west-2' },
];

export const accountBrandOptions = [
  { label: 'ABI', value: 'abi' },
  { label: 'AUDI', value: 'audi' },
  { label: 'BENTLEY', value: 'bentley' },
  { label: 'CARIAD', value: 'cariad' },
  { label: 'VOLKSWAGEN', value: 'volkswagen' },
];

export const dataClassificationOptions = [
  { label: 'Internal', value: 'intern' },
  { label: 'Confidential', value: 'vertraulich' },
  { label: 'Secret', value: 'geheim' },
];

export const grcRiskValueOptions = [
  { label: 'Very high risk', value: 'sehr schwer (1)' },
  { label: 'Medium risk', value: 'mittelschwer (2)' },
  { label: 'Low risk', value: 'leicht (3)' },
  { label: 'Very low risk', value: 'gering (4)' },
];

export const accountEnvironment = [
  { label: 'Sandbox', value: 'sandbox' },
  { label: 'Dev', value: 'dev' },
  { label: 'Test', value: 'test' },
  { label: 'Prod', value: 'prd' },
];

export const accountFilterOptions = [
  { value: 'project_name', label: 'Project name' },
  { value: 'account_id', label: 'Account ID' },
  { value: 'account_alias', label: 'Account Alias' },
  { value: 'cost_center', label: 'Cost Center' },
  { value: 'requestor_email', label: 'Requestor email' },
  { value: 'person_name', label: 'Person name' },
];

export const defaultRegoins = [
  { label: 'EU (Frankfurt) - eu-central-1', value: 'eu-central-1' },
];

export const documentationLinks = [
  { title: 'Audi Cloud Foundation - Home', value: 'https://volkswagen-net.de/wikis/x/fiz2VQ' },
  { title: 'Overview Products and Services', value: 'https://volkswagen-net.de/wikis/x/JS32VQ' },
  { title: 'FinOps - Cost Management', value: 'https://volkswagen-net.de/wikis/x/MJAYbg' },
  { title: 'Prof Services - Cloud Consulting', value: 'https://volkswagen-net.de/wikis/x/aoIdfg' },
  { title: 'Everything for Developers - please share', value: 'https://volkswagen-net.de/wikis/x/gAglfw' },
];

// Available status for status: open, ongoing, done, failed
export const accountRootStatus = [
  {
    statusName: 'onboarding',
    statusLabel: 'Onboarding',
    statusDesc: 'The onboarding includs all prerequisites to create, manage and bill your account.',
    statusDocuUrl: 'https://volkswagen-net.de/wikis/x/fiz2VQ',
    statusDocUrl: 'test',
    progress: 'open',
  },
  {
    statusName: 'configuration',
    statusLabel: 'Configuration',
    statusDesc: 'All preceeding configurations for your account management will be done now.',
    statusDocuUrl: 'https://volkswagen-net.de/wikis/x/fiz2VQ',
    progress: 'open',
  },
  {
    statusName: 'creation',
    statusLabel: 'Creation',
    statusDesc: 'Your account is currently under creation which includes the enrollment of all our mandatory services for your security.',
    statusDocuUrl: 'https://volkswagen-net.de/wikis/x/fiz2VQ',
    progress: 'open',
  },
  {
    statusName: 'ready',
    statusLabel: 'Ready-To-Use Account',
    statusDesc: 'Your AWS account is ready to use - check the Developer Docu and our offers to start right away - happy coding!',
    statusDocuUrl: 'https://volkswagen-net.de/wikis/x/fiz2VQ',
    progress: 'open',
  },
];

export const projectStatuses = [
  { label: 'active', value: 'active' },
  { label: 'closed', value: 'closed' },
  { label: 'pending', value: 'pending' },
];
