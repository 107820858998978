import {
  regionsOptions, allRegionsOptions, accountBrandOptions, accountEnvironment, projectStatuses,
} from './options';

export const accountDetailsFields = [
  {
    name: 'cloud_provider', label: 'Cloud Provider', type: 'text', required: true, readOnly: true, description: 'Cloud provider for account',
  },
  {
    name: 'account_brand', label: 'Account Company', type: 'select', required: true, options: accountBrandOptions, description: 'Company which uses the account',
  },
  {
    name: 'account_name', label: 'Account Name', type: 'text', required: true, readOnly: true, description: 'Account name. Is generated automatically.',
  },
  {
    name: 'account_email', label: 'Account E-mail', type: 'text', required: true, readOnly: true, userVisible: false, description: 'E-mail addreess on which account was created.',
  },
  {
    name: 'project_name', label: 'Project Full Name', type: 'text', required: true, regex: /^[A-Za-z0-9-]{1,25}$/, description: 'Project full name. Please use letters,numbers and "-" only.',
  },
  {
    name: 'account_project', label: 'Project IT-SYSTEM', type: 'text', required: true, regex: /^[A-Z0-9]{1,30}$/, description: 'Higher-level unit to which different applications, services and/or projects are assigned, like ACDC, AAP, COCOS (using abbreviation) - UPPERCASE required',
  },
  {
    name: 'account_spec', label: 'Account Specification', type: 'text', required: true, regex: /^[A-Za-z0-9-]{1,30}$/, description: 'Project or application specific identifier like function, component, area (e.g. "mgmt", "eu", "prelive")',
  },
  {
    name: 'regions', label: 'Allowed Regions', type: 'multiSelect', required: true, options: regionsOptions, description: 'Regions where account load is allowed to run. You can choose additional regions, the default regions are fixed.',
  },
  {
    name: 'account_description', label: 'Account Description', type: 'textArea', required: true, regex: /^[\S ]{1,200}$/, description: 'Short Project / Account description (max. 200 chars)',
  },
  {
    name: 'account_id', label: 'Account ID', type: 'text', required: true, readOnly: true,
  },
  {
    name: 'cost_center', label: 'Cost Center', type: 'text', required: false, regex: /^[1-9][\d]{3,6}$/, description: 'Cost center to charge (digits only, only when PSP Element not provided)',
  },
  {
    name: 'cost_psp_element', label: 'Cost PSP Element', type: 'text', required: false, description: 'PSP element (only when Cost Center not provided)',
  },
  {
    name: 'cost_assignment', label: 'Cost Assignment', type: 'text', required: false, description: 'Cost Assignment',
  },
  {
    name: 'cost_center_wa', label: 'Cost Center WA', type: 'text', required: false, regex: /^\d{1,12}$/, description: 'WA (Werksauftragsnummer) to charge (Digits only)',
  },
  {
    name: 'budget_limit_alert', label: 'Budget Limit Alert ($)', type: 'text', required: true, regex: /^\d+(,\d{1,2})?$/, description: 'Yearly budget for this account in Dollar',
  },
  {
    name: 'leanix_id', label: 'LEANIX-ID', type: 'text', required: false, regex: /^[0-9]*$/, description: 'Please specify LEANIX-ID (Digits only)',
  },
];

export const accountToBeDeleted = [
  {
    name: 'account_id', label: 'Account ID', type: 'text', required: true, readOnly: true,
  },
  {
    name: 'requestor_email', label: 'Requestor Email', type: 'text', required: true, readOnly: true,
  },
  {
    name: 'project_name', label: 'Project Name', type: 'text', required: true, readOnly: true,
  },
];

export const projectDetailsFields = [
  {
    name: 'project_name', label: 'Project Name', type: 'textProject', required: true, readOnly: false, regex: /^[A-Za-z0-9-]{1,25}$/, description: 'Project full name. Please use letters,numbers and "-" only.',
  },
  {
    name: 'project_id', label: 'Project IT-System', type: 'textProject', required: true, readOnly: true, regex: /^[A-Z0-9]{1,30}$/, description: 'Higher-level unit to which different applications, services and/or projects are assigned, like ACDC, AAP, COCOS (using abbreviation) - UPPERCASE require',
  },
  {
    name: 'project_description', label: 'Project Description', type: 'textAreaProject', required: false, readOnly: false, regex: /^[\s\S]{1,500}$/, description: 'Short Project description (max. 500 chars)',
  },
  {
    name: 'project_owner',
    label: 'Project Owner',
    type: 'mapProject',
    required: true,
    description: 'Contact of person who is responsible for this project',
    children: [
      {
        name: 'full_name', label: 'Full name', type: 'text', required: true, id: 'po_full_name', regex: /^[a-zA-ZäöüÄÖÜß ,.'-]+$/,
      },
      {
        name: 'email', label: 'Email', type: 'text', required: true, id: 'po_email', regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      },
    ],
  },
  {
    name: 'project_finops_contact',
    label: 'FinOps Contact',
    type: 'mapProject',
    required: true,
    description: 'Contact of person who is responsible for billing in this project.',
    children: [
      {
        name: 'full_name', label: 'Full name', type: 'text', required: true, id: 'fo_full_name', regex: /^[a-zA-ZäöüÄÖÜß ,.'-]+$/,
      },
      {
        name: 'email', label: 'Email', type: 'textProject', required: true, id: 'fo_email', regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      },
    ],
  },
  {
    name: 'project_cost_center', label: 'Cost Center', type: 'textProject', required: false, readOnly: false, regex: /^[1-9][\d]{3,6}$/, description: 'Cost center to charge (Digits only)',
  },
  {
    name: 'project_cost_psp_element', label: 'PSP element cost', type: 'textProject', required: false, description: 'PSP element (only when Cost Center not provided)',
  },
  {
    name: 'project_cost_assignment', label: 'Cost Assignment', type: 'textProject', required: false, description: 'Cost Assignment',
  },
  {
    name: 'project_budget_limit_alert', label: 'Budget Limit Alert', type: 'textProject', required: false, readOnly: false, description: 'Budget Limit Alert',
  },
  {
    name: 'project_accounts', label: 'Project Accounts', type: 'listProject', required: true, readOnly: false, description: 'Accounts that belongs to the project',
  },
  {
    name: 'project_status', label: 'Project status', type: 'selectProject', required: true, options: projectStatuses, description: 'Status of Project',
  },
];

export const accountsAndPermissionsFirst = [
  {
    name: 'security_contact',
    label: 'Security Contact',
    type: 'map',
    required: true,
    description: 'Contact of person who will perform security tasks in this account.',
    children: [
      {
        name: 'full_name', label: 'Full name', type: 'text', readOnly: false, required: true, id: 'sc_full_name', regex: /^[a-zA-ZäöüÄÖÜß ,.'-]+$/,
      },
      {
        name: 'email', label: 'Email', type: 'text', readOnly: false, required: true, id: 'sc_email', regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      },
    ],
  },
  {
    name: 'operations_contact',
    label: 'Operation Contact',
    type: 'map',
    required: true,
    description: 'Contact of person who will perform operational tasks in this account',
    children: [
      {
        name: 'full_name', label: 'Full name', type: 'text', readOnly: false, required: true, id: 'oc_full_name', regex: /^[a-zA-ZäöüÄÖÜß ,.'-]+$/,
      },
      {
        name: 'email', label: 'Email', type: 'text', readOnly: false, required: true, id: 'oc_email', regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      },
    ],
  },
  {
    name: 'requestor_contact',
    label: 'Account Owner',
    type: 'map',
    required: true,
    description: 'Contact of person who own this account',
    children: [
      {
        name: 'full_name', label: 'Full name', type: 'text', readOnly: false, required: true, id: 'rc_full_name', regex: /^[a-zA-ZäöüÄÖÜß ,.'-]+$/,
      },
      {
        name: 'email', label: 'Email', type: 'text', readOnly: false, required: true, id: 'rc_email', regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      },
    ],
  },
];

export const accountsAndPermissionSecond = [
  {
    name: 'additional_owners', label: 'Additional Account Owners', type: 'listAdditionalOwners', required: true, readOnly: false, description: 'Contact of persons who can operate this account', regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  },
  {
    name: 'new_additional_owner',
    label: 'New Additional Owner',
    type: 'map',
    required: true,
    description: 'Use this to add new additional owner',
    children: [
      {
        name: 'full_name', label: 'Full name', type: 'text', required: true, id: 'ao_full_name', regex: /^[a-zA-ZäöüÄÖÜß ,.'-]+$/,
      },
      {
        name: 'email', label: 'Email', type: 'text', required: true, id: 'ao_email', regex: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      },
    ],
  },
];
