import React, { useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../AccountDetails.scss';
import { accountDetailsFields } from '../../../helpers/inputFields';
import { axiosInstance } from '../../../axios';
import MultiInputFields from '../../shared/MultiInputFields';
import { editAccount } from '../../../app/slices/accountListSlice';
import DataProtectionFields from '../DataProtectionFields';
import { accountState } from '../../../helpers/objects';
import { Alert, Rootstate } from '../AccountDetailsHelpers';

interface Props {
  handleAlert: ({ open, type }: Alert) => void;
}

const ClosedAccount: FC<Props> = ({ handleAlert }) => {
  const dispatch = useDispatch();
  const selectedAccountId = useSelector((state: Rootstate) => state.accounts.selectedAccount);
  const userRole = useSelector((state: Rootstate) => state.user.userRole);

  const fetchData = async () => {
    try {
      if (selectedAccountId) {
        dispatch(editAccount(accountState));
        const response = await axiosInstance().then((service) => service?.get(`/accounts/${selectedAccountId}`));
        const additional_owners = await axiosInstance().then((service) => service?.get(`/accounts/${selectedAccountId}/additional_owners`));
        const data = response?.data[0];
        Object.keys(accountState).map((key) => {
          if (data[key] === undefined) {
            data[key] = '';
          }
          return key;
        });
        const currentAccount = {
          cloud_provider: 'AWS',
          account_brand: data.brand,
          account_email: data.account_email,
          account_env: data.account_env,
          account_project: data.project,
          account_spec: data.account_spec,
          cost_center: data.cost_center,
          cost_psp_element: data.cost_psp_element,
          cost_assignment: data.cost_assignment || '8150000200',
          cost_center_wa: data.cost_center_wa,
          account_description: data.ext_info.comment,
          account_id: data.account_id,
          regions: data.allowed_regions,
          operations_contact: {
            full_name: data.contacts.operations.full_name,
            email: data.contacts.operations.email,
          },
          security_contact: {
            full_name: data.contacts.security.full_name,
            email: data.contacts.security.email,
          },
          requestor_contact: {
            full_name: data.ext_info.requestorFullName,
            email: data.ext_info.requestorEmailAddress,
          },
          account_name: data.account_name,
          project_name: data.account_name,
          budget_limit_alert: data.budget_limit_alert === '' ? '0,00' : data.budget_limit_alert,
          leanix_id: data.leanix_id,
          architect_assessment_performed: data.architect_assessment_performed,
          architect_assessment_date: data.architect_assessment_date,
          architect_fullname: data.architect_fullname,
          security_checkup_performed: data.security_checkup_performed,
          security_checkup_date: data.security_checkup_date,
          requestor_email: data.requestorEmailAddress,
          login_url: `https://${data.alias}.signin.aws.amazon.com/console`,
          welcome_mail_sent: data.welcome_mail_sent,
          pending_deletion: data.pending_deletion,
          additional_owners: additional_owners?.status !== 204 ? additional_owners?.data : [],
          new_additional_owner: {
            email: '',
            full_name: '',
          },
        };
        dispatch(editAccount(
          currentAccount,
        ));
      }
    } catch (error: any) {
      handleAlert({ open: true, type: 'error', message: error.message });
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedAccountId]);

  return (
    <div className="account-details__form-group">
      <form action="">
        {accountDetailsFields.map((item) => (
          <div key={item.name}>
            {userRole === 'user' && item.userVisible === false ? '' : <MultiInputFields item={item} />}
          </div>
        ))}
        <DataProtectionFields accountListType="active" />
      </form>
    </div>
  );
};

export default ClosedAccount;
