import React from 'react';
import { useSelector } from 'react-redux';
import CreationStepOne from './CreationStepOne';
import CreationStepTwo from './CreationStepTwo';
import CreationStepThree from './CreationStepThree';
import CreationStepFour from './CreationStepFour';
import CreationStepFive from './CreationStepFive';
import CreationStepSix from './CreationStepSix';
import './AccountCreationSteps.scss';

interface RootState {
  step: Step;
}

interface Step {
  step: number
}

const AccountCreationSteps = () => {
  const step = useSelector((state: RootState) => state.step.step);

  const renderCreationStep = () => {
    switch (step) {
      case 1:
        return (
          <CreationStepOne />
        );
        break;
      case 2:
        return (
          <CreationStepTwo />
        );
        break;
      case 3:
        return (
          <CreationStepThree />
        );
        break;
      case 4:
        return (
          <CreationStepFour />
        );
        break;
      case 5:
        return (
          <CreationStepFive />
        );
        break;
      case 6:
        return (
          <CreationStepSix />
        );
        break;
      default:
        return null;
    }
  };

  return (
    <div className="creation-step">
      {renderCreationStep()}
    </div>
  );
};

export default AccountCreationSteps;
