import { signingKeys } from './signingKeys';

let prepConfiguration;

if (process.env.REACT_APP_ENV === 'local') {
  prepConfiguration = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: 'id_token token',
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    scope: 'openid',
    authority: process.env.REACT_APP_AUTHORITY,
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
    automaticSilentRenew: false,
    loadUserInfo: true,
  };
} else {
  prepConfiguration = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: 'id_token token',
    post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    scope: 'openid',
    authority: process.env.REACT_APP_AUTHORITY,
    silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URI,
    automaticSilentRenew: false,
    loadUserInfo: true,
    metadata: {
      issuer: process.env.REACT_APP_ISSUER,
      authorization_endpoint:
        process.env.REACT_APP_AUTHORIZATION_ENDPOINT,
      token_endpoint:
        process.env.REACT_APP_TOKEN_ENDPOINT,
      userinfo_endpoint:
        process.env.REACT_APP_USERINFO_ENDPOINT,
      jwks_uri:
        process.env.REACT_APP_JWKS_URI,
    },
    signingKeys,
  };
}

const configuration = prepConfiguration;

export default configuration;
