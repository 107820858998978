import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import AccountDetails from '../../components/AccountDetails/AccountDetails';
import AccountList from '../../components/AccountList/AccountList';
import AccountAddInfos from '../../components/AccountAddInfo/AccountAddInfos';
import CreateAccountModal from '../../components/CreateAccountModal/CreateAccountModal';
import AccountCreationSteps from '../../components/AccountCreationSteps/AccountCreationSteps';

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  containner: {
    minWidth: '1200px',
  },
  snackBar: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const AccountManagement = () => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseAlert = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={1} className={classes.containner}>
      <Grid item xs={3}>
        <AccountList openCreateAccountModal={(value) => setShowModal(value)} />
      </Grid>
      <Grid item xs={6}>
        <AccountDetails openCreateAccountModal={(value) => setShowModal(value)} />
      </Grid>
      <Grid item xs={3}>
        <AccountAddInfos />
      </Grid>
      <CreateAccountModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleAlert={(value: any) => {
          setAlertType(value.type);
          setOpen(value.open);
          setErrorMessage(value.message);
        }}
      >
        <AccountCreationSteps />
      </CreateAccountModal>

      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        className={classes.snackBar}
      >
        <Alert onClose={handleCloseAlert} severity={alertType}>
          {alertType === 'success' ? 'The new account has been successfully requested!' : errorMessage}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default AccountManagement;
