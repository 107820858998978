import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './AccountCreationSteps.scss';
import { accountDetailsFields } from '../../helpers/inputFields';
import MultiInputFields from '../shared/MultiInputFields';
import { setIsCompletedFilled } from '../../app/slices/accountCreationStepsSlice';

interface RootState {
  accounts: {
    account: Account
  }
}

interface Account {
 [key: string]: string,
}

const CreationStepTwo = () => {
  const dispatch = useDispatch();
  const account = useSelector((state: RootState) => state.accounts.account);
  const stepTwoFields = ['account_brand', 'account_project', 'account_spec', 'project_name', 'regions', 'account_description'];
  const filledChecks = stepTwoFields.map((item) => account[item]);
  const filteredFields = accountDetailsFields.filter(
    (item) => stepTwoFields.indexOf(item.name) > -1,
  );

  // To make all fields editable in steop two
  filteredFields.forEach((element) => {
    element.readOnly = false;
  });

  const checkValidateALlFields = () => {
    for (let i = 0; i < filledChecks.length; i++) {
      if (!filledChecks[i] || !filledChecks[3].length) {
        return dispatch(setIsCompletedFilled(false));
      }
    }
    dispatch(setIsCompletedFilled(true));
  };

  useEffect(() => {
    checkValidateALlFields();
  }, [account]);

  return (
    <div className="creation-step-two">
      <h3>Provide details for the AWS account (2/6)</h3>
      {filteredFields.map((item) => (
        <MultiInputFields item={item} key={item.name} />
      ))}
    </div>
  );
};

export default CreationStepTwo;
