import React, { useEffect, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import '../AccountDetails.scss';
import {
  Rootstate, Alert, projectMandatoryFields, PreviousProject,
} from '../AccountDetailsHelpers';
import { projectDetailsFields } from '../../../helpers/inputFields';
import { axiosInstance } from '../../../axios';
import MultiInputFields from '../../shared/MultiInputFields';
import { editProject, fetchProjects } from '../../../app/slices/accountListSlice';
import SpeedDial from '../../shared/SpeedDial';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import { projectState } from '../../../helpers/objects';

interface Props {
  handleAlert: ({ open, type }: Alert) => void;
}

const Project: FC<Props> = ({ handleAlert }) => {
  const dispatch = useDispatch();
  const userRole = useSelector((state: Rootstate) => state.user.userRole);
  const project = useSelector((state: Rootstate) => state.accounts.project);
  const selectedProject = useSelector((state: Rootstate) => state.accounts.selectedProject);
  const [actionType, setActionType] = useState('');
  const [prevProject, setPreviousProject] = useState<PreviousProject | undefined>();
  const [openModal, setOpenModal] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [allFilled, setAllFilled] = useState(true);

  const mandatoryChecks = projectMandatoryFields.map((item) => project[item]);
  const isValidated = useSelector((state: Rootstate) => state.step.isValidated);

  const checkValidateAllFields = () => {
    for (let i = 0; i < mandatoryChecks.length; i++) {
      let isEmpty;
      // checks if the field is undefined to prevent errors in Object.values function
      if (typeof mandatoryChecks[i] === 'undefined') {
        isEmpty = true;
      } else {
        isEmpty = Object.values(mandatoryChecks[i]).some((x) => x === '');
      }
      if ((mandatoryChecks[i] === '') || isEmpty) { return setAllFilled(false); }
    }
    setAllFilled(true);
  };

  // disable or enable save button
  // ADMIN can ALWAYS save
  useEffect(() => {
    if (((JSON.stringify(prevProject) !== JSON.stringify(project))
      && isValidated && allFilled) || userRole === 'admin') {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [project, allFilled]);

  useEffect(() => {
    checkValidateAllFields();
  }, [project]);

  const fetchData = async () => {
    try {
      if (selectedProject) {
        dispatch(editProject(projectState));
        const response = await axiosInstance().then((service) => service?.get(`/projects/${selectedProject}`));
        const data = response?.data;
        console.log(data);
        const currentProject = {
          project_name: data.project_name,
          project_id: data.project_id,
          project_description: data.project_description,
          project_owner: {
            full_name: data.project_owner.full_name,
            email: data.project_owner.email,
          },
          project_finops_contact: {
            full_name: data.project_finops_contact.full_name,
            email: data.project_finops_contact.email,
          },
          project_cost_center: data.project_cost_center,
          project_cost_psp_element: data.project_cost_psp_element,
          project_cost_assignment: data.project_cost_assignment,
          project_budget_limit_alert: data.project_budget_limit_alert === '' ? '0,00' : data.project_budget_limit_alert,
          project_status: data.project_status,
          project_accounts: data.project_accounts,
        };
        setPreviousProject(
          currentProject,
        );
        dispatch(editProject(
          currentProject,
        ));
      }
    } catch (error: any) {
      handleAlert({ open: true, type: 'error', message: error.message });
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedProject]);

  const openConfirmModal = (type: string) => {
    setOpenModal(true);
    setActionType(type);
  };

  const handleSubmit = async () => {
    if (actionType === 'save') {
      // admin can always save
      if ((allFilled || userRole === 'admin') && prevProject) {
        try {
          const newObjBody: any = {};
          for (const [key, val] of Object.entries(prevProject)) {
            for (const [key2, val2] of Object.entries(project)) {
              if (key === key2 && val
                !== val2) {
                newObjBody[key2] = val2;
              }
            }
          }

          await axiosInstance().then((service) => service?.patch(`/projects/${selectedProject}`, newObjBody))
            .then((res) => {
              dispatch(fetchProjects());
              handleAlert({ open: true, type: 'success', message: 'Successfully saved the changes' });
              setOpenModal(false);
              setDisabledSave(true);
              fetchData();

              if (res?.status && res.status >= 400) {
                const errResponse = res?.data.message;
                handleAlert({ open: true, type: 'error', message: errResponse });
              }
            });
        } catch (error: any) {
          handleAlert({ open: true, type: 'error', message: error.message });
        }
      } else {
        handleAlert({ open: true, type: 'error', message: 'Please fill data into all required input fields' });
      }
    }
  };

  const actions = [
    {
      icon: <SaveRoundedIcon />, name: 'Save', disabled: disabledSave, handleClick: () => openConfirmModal('save'),
    },
  ];

  const userReadOnly = [
    'project_accounts',
  ];
  const adminReadOnly = [''];

  useEffect(() => {
    if (userRole === 'user') {
      projectDetailsFields.forEach((element) => {
        if (userReadOnly.includes(element.name)) {
          element.readOnly = true;
        }
      });
    } else if (userRole === 'admin') {
      projectDetailsFields.forEach((element) => {
        if (adminReadOnly.includes(element.name)) {
          element.readOnly = true;
        }
      });
    }
  }, [userRole]);

  return (
    <div className="account-details__form-group">
      <form action="">
        {projectDetailsFields.map((item) => (
          <div key={item.name}>
            <MultiInputFields item={item} isProjectField />
          </div>
        ))}
      </form>
      <div className="account-details__button-group">
        <SpeedDial actions={actions} />
      </div>
      <ConfirmModal
        type={actionType}
        name={project.project_name}
        show={openModal}
        handleClose={() => setOpenModal(false)}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default Project;
