/* eslint-disable max-len */
import React, { useEffect } from 'react';
import './CustomWelcomePage.scss';
import { Button } from '@audi/audi-ui-react';

const CustomWelcomePage = () => {
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  return (
    <div className="welcome">
      <div className="welcome__inner">
        <h1>AUDI ACCOUNT MANAGEMENT</h1>
        <p>
          ACS Account Management Tool is a self-provisioning tool, that gives users the possibility to request, edit or
          delete cloud accounts.
        </p>
        <a href="/main">
          <Button variant="primary">Enter the cloud &#8594;</Button>
        </a>
      </div>
      <div className="welcome__footer">
        <a href="/legal/imprint">
          <Button variant="text" className="welcome__legal">
            Imprint
          </Button>
        </a>
      </div>
    </div>
  );
};

export default CustomWelcomePage;
