import React from 'react';
import './ie.polyfills';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthenticationProvider, oidcLog } from '@axa-fr/react-oidc-context';
import { Provider } from 'react-redux';
import store from './app/store';
import CustomCallback from './pages/CallBack/CustomCallback';
import CustomWelcomePage from './pages/CallBack/CustomWelcomePage';
import oidcConfiguration from './configuration';
import Routes from './Router/Routes';

const App = () => (
  <AuthenticationProvider
    configuration={oidcConfiguration}
    loggerLevel={oidcLog.DEBUG}
    isEnabled
    callbackComponentOverride={CustomCallback}
    notAuthenticated={CustomWelcomePage}
    notAuthorized={CustomWelcomePage}
    authenticating={CustomWelcomePage}
    sessionLostComponent={CustomWelcomePage}
  >
    <Provider store={store}>
      <Router>
        <Routes />
      </Router>
    </Provider>
  </AuthenticationProvider>
);

export default App;
