import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import './AccountDetails.scss';
import { Button } from '@audi/audi-ui-react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import ActiveAccount from './ActiveAccount/ActiveAccount';
import PendingAccount from './PendingAccount/PendingAccount';
import AccountToBeDeleted from './AccountToBeDeleted/AccountToBeDeleted';
import ClosedAccount from './CLosedAccount/CLosedAccount';
import Project from './Project/Project';
import { Rootstate } from './AccountDetailsHelpers';

type Props = {
  openCreateAccountModal: (x: boolean) => void;
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const AccountDetails:FC<Props> = ({ openCreateAccountModal }) => {
  const classes = useStyles();
  const selectedAccountId = useSelector((state: Rootstate) => state.accounts.selectedAccount);
  const selectedProject = useSelector((state: Rootstate) => state.accounts.selectedProject);
  const directory = useSelector((state: Rootstate) => state.accounts.directory);
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [message, setMessage] = useState('');

  const renderAccountDetails = () => {
    if (directory === 'active account') {
      return (
        <ActiveAccount
          handleAlert={(value: any) => {
            setAlertType(value.type);
            setOpen(value.open);
            setMessage(value.message);
          }}
        />
      );
    }
    if (directory === 'pending account') {
      return (
        <PendingAccount
          handleAlert={(value: any) => {
            setAlertType(value.type);
            setOpen(value.open);
            setMessage(value.message);
          }}
        />
      );
    }
    if (directory === 'accountToBeDeleted') {
      return (
        <AccountToBeDeleted
          handleAlert={(value: any) => {
            setAlertType(value.type);
            setOpen(value.open);
            setMessage(value.message);
          }}
        />
      );
    }
    if (directory === 'closedAccount') {
      return (
        <ClosedAccount
          handleAlert={(value: any) => {
            setAlertType(value.type);
            setOpen(value.open);
            setMessage(value.message);
          }}
        />
      );
    }
    if (directory === 'project') {
      return (
        <Project
          handleAlert={(value: any) => {
            setAlertType(value.type);
            setOpen(value.open);
            setMessage(value.message);
          }}
        />
      );
    }
  };

  const handleCloseAlert = () => {
    setOpen(false);
  };

  return (
    <div className="account-details">
      <div className="account-details__header">
        Details
      </div>
      <div className="account-details__container">
        {
          (!selectedAccountId && !selectedProject) ? (
            <div>
              <p>Select an existing account to display the account data</p>
              <p>You can also apply for a new account by clicking New Account</p>
            </div>
          ) : (
            <>
              { renderAccountDetails() }
            </>
          )
        }

      </div>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
        className={classes.root}
      >
        <Alert onClose={handleCloseAlert} severity={alertType}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AccountDetails;
