import React, { useEffect, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ThumbDownAltRoundedIcon from '@material-ui/icons/ThumbDownAltRounded';
import '../AccountDetails.scss';
import {
  mandatoryFields, Rootstate, Alert, PreviousAccount,
} from '../AccountDetailsHelpers';
import { accountDetailsFields } from '../../../helpers/inputFields';
import { axiosInstance } from '../../../axios';
import MultiInputFields from '../../shared/MultiInputFields';
import { editAccount, fetchPendingAccounts, selectAccount } from '../../../app/slices/accountListSlice';
import DataProtectionFields from '../DataProtectionFields';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import RejectModal from '../ConfirmModal/RejectModal';
import SpeedDial from '../../shared/SpeedDial';
import { accountState } from '../../../helpers/objects';

interface Props {
  handleAlert: ({ open, type }: Alert) => void;
}

const pendingAccountDetailsFields = accountDetailsFields.filter((item) => item.name !== 'login_url' && item.name !== 'account_id' && item.name !== 'account_email');

const PendingAccount: FC<Props> = ({ handleAlert }) => {
  const dispatch = useDispatch();
  const userRole = useSelector((state: Rootstate) => state.user.userRole);
  const account = useSelector((state: Rootstate) => state.accounts.account);
  const selectedAccountId = useSelector((state: Rootstate) => state.accounts.selectedAccount);
  const [disabledSaveNew, setDisabledSaveNew] = useState(true);
  const [disabledSave, setDisabledSave] = useState(true);
  const [prevAccount, setPreviousAccount] = useState<PreviousAccount | undefined>();
  const [actionType, setActionType] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [requestStatus, setRequestStatus] = useState('');
  const [allFilled, setAllFilled] = useState(true);
  const mandatoryChecks = mandatoryFields.map((item) => account[item]);
  const isValidated = useSelector((state: Rootstate) => state.step.isValidated);

  // disable or enable save as new button
  useEffect(() => {
    if ((`${account.account_name}` !== `${account.account_brand}-${account.account_env}-${account.account_project}-${account.account_spec}`) && isValidated) {
      setDisabledSaveNew(false);
    } else {
      setDisabledSaveNew(true);
    }
  }, [account.project_name,
    account.account_project, account.account_spec, account.account_env, isValidated]);

  const checkValidateALlFields = () => {
    if (prevAccount?.project_name) {
      for (let i = 0; i < mandatoryChecks.length; i++) {
        const isEmpty = Object.values(mandatoryChecks[i]).some((x) => x === '');
        if ((mandatoryChecks[i] === '') || isEmpty) { return setAllFilled(false); }
      }
      setAllFilled(true);
    }
  };

  // disable or enable save button
  // ADMIN can ALWAYS save
  useEffect(() => {
    checkValidateALlFields();
    if (((JSON.stringify(prevAccount) !== JSON.stringify(account)) && isValidated && allFilled) || userRole === 'admin') {
      setDisabledSave(false);
    } else {
      setDisabledSave(true);
    }
  }, [account, isValidated, allFilled]);

  useEffect(() => {
    dispatch(editAccount({ ...account, requestor_email: account.requestor_contact.email }));
  }, [account.requestor_contact.email]);

  const fetchData = async () => {
    try {
      if (selectedAccountId) {
        dispatch(editAccount(accountState));
        const response = await axiosInstance().then((service) => service?.get(`/requests/${selectedAccountId}`));
        const data = response?.data[0];
        Object.keys(accountState).map((key) => {
          if (data[key] === undefined) {
            data[key] = '';
          }
          return key;
        });
        const currentAccount = {
          ...account,
          cloud_provider: 'AWS',
          account_brand: data.account_brand,
          account_env: data.account_env,
          account_project: data.account_project,
          account_spec: data.account_spec,
          cost_center: data.cost_center,
          cost_psp_element: data.cost_psp_element,
          cost_assignment: data.cost_assignment || '8150000200',
          cost_center_wa: data.cost_center_wa,
          account_description: data.account_description,
          regions: data.regions,
          operations_contact: {
            full_name: data.operations_contact.full_name,
            email: data.operations_contact.email,
          },
          security_contact: {
            full_name: data.security_contact.full_name,
            email: data.security_contact.email,
          },
          requestor_contact: {
            full_name: data.requestor_contact.full_name,
            email: data.requestor_contact.email,
          },
          account_name: `${data.account_brand}-${data.account_env}-${data.account_project}-${data.account_spec}`,
          project_name: data.project_name,
          budget_limit_alert: data.budget_limit_alert === '' ? '0,00' : data.budget_limit_alert,
          leanix_id: data.leanix_id,
          architect_assessment_date: data.architect_assessment_date,
          architect_assessment_performed: data.architect_assessment_performed,
          architect_fullname: data.architect_fullname,
          security_checkup_date: data.security_checkup_date,
          security_checkup_performed: data.security_checkup_performed,
          welcome_mail_sent: data.welcome_mail_sent,
        };
        setPreviousAccount(
          currentAccount,
        );
        setRequestStatus(data.request_status);
        dispatch(editAccount(
          currentAccount,
        ));
      }
    } catch (error: any) {
      handleAlert({ open: true, type: 'error', message: error.message });
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedAccountId]);

  const openConfirmModal = (type: string) => {
    setOpenModal(true);
    setActionType(type);
  };

  const handleOpenRejectModal = (type: string) => {
    setOpenRejectModal(true);
    setActionType(type);
  };

  const handleSubmit = async (value?: string) => {
    if (actionType === 'save new') {
      // admin can always save
      if (allFilled || userRole === 'admin') {
        const body = { ...account };
        delete body.login_url;
        delete body.cloud_provider;
        delete body.account_name;
        delete body.request_status;
        delete body.reason_for_rejection;
        try {
          await axiosInstance().then((service) => service?.post('requests', body))
            .then((res) => {
              dispatch(fetchPendingAccounts());
              handleAlert({ open: true, type: 'success', message: 'Successfully created request for a new account' });
              setOpenModal(false);
              dispatch(selectAccount({ accountId: res?.data.request_id, directory: 'pending account' }));
              setDisabledSaveNew(true);
              if (res?.status && res.status >= 400) {
                const errResponse = res?.data.message;
                handleAlert({ open: true, type: 'error', message: errResponse });
              }
            });
        } catch (error: any) {
          handleAlert({ open: true, type: 'error', message: error.message });
        }
      } else {
        handleAlert({ open: true, type: 'error', message: 'Please fill data into all required input fields' });
      }
    }
    if (actionType === 'delete') {
      try {
        await axiosInstance().then((service) => service?.delete(`requests/${selectedAccountId}`))
          .then(() => {
            dispatch(fetchPendingAccounts());
            handleAlert({ open: true, type: 'success', message: 'Successfully deleted account' });
            setOpenModal(false);
            dispatch(selectAccount(''));
          });
      } catch (error: any) {
        handleAlert({ open: true, type: 'error', message: error.message });
      }
    }
    if (actionType === 'approve') {
      try {
        await axiosInstance().then((service) => service?.patch(`/requests/${selectedAccountId}`, { request_status: 'approve' }))
          .then(() => {
            dispatch(fetchPendingAccounts());
            handleAlert({ open: true, type: 'success', message: 'Successfully approved the request' });
            setOpenModal(false);
            fetchData();
          });
      } catch (error: any) {
        handleAlert({ open: true, type: 'error', message: error.message });
      }
    }
    if (actionType === 'reject') {
      try {
        await axiosInstance().then((service) => service?.patch(`/requests/${selectedAccountId}`, { request_status: 'reject', reason_for_rejection: value }))
          .then(() => {
            dispatch(fetchPendingAccounts());
            handleAlert({ open: true, type: 'success', message: 'Successfully rejected the request' });
            setOpenRejectModal(false);
            fetchData();
          });
      } catch (error: any) {
        handleAlert({ open: true, type: 'error', message: error.message });
      }
    }
    if (actionType === 'save') {
      // admin can always save
      if ((allFilled || userRole === 'admin') && prevAccount) {
        try {
          const newObjBody: any = {};
          for (const [key, val] of Object.entries(prevAccount)) {
            for (const [key2, val2] of Object.entries(account)) {
              if (key === key2 && val
               !== val2) {
                newObjBody[key2] = val2;
              }
            }
          }

          await axiosInstance().then((service) => service?.patch(`/requests/${selectedAccountId}`, newObjBody))
            .then((res) => {
              dispatch(fetchPendingAccounts());
              handleAlert({ open: true, type: 'success', message: 'Successfully saved the changes' });
              setOpenModal(false);
              setDisabledSave(true);
              fetchData();

              if (res?.status && res.status >= 400) {
                const errResponse = res?.data.message;
                handleAlert({ open: true, type: 'error', message: errResponse });
              }
            });
        } catch (error: any) {
          handleAlert({ open: true, type: 'error', message: error.message });
        }
      } else {
        handleAlert({ open: true, type: 'error', message: 'Please fill data into all required input fields' });
      }
    }
  };

  const actions = [
    {
      icon: <SaveRoundedIcon />, name: 'Save', disabled: disabledSave, handleClick: () => openConfirmModal('save'),
    },
    {
      icon: <AddBoxRoundedIcon />, name: 'Save as new', disabled: disabledSaveNew, handleClick: () => openConfirmModal('save new'),
    },
    {
      icon: <DeleteForeverRoundedIcon />, name: 'Delete', disabled: false, handleClick: () => openConfirmModal('delete'),
    },
    {
      icon: <ThumbDownAltRoundedIcon />, name: 'Reject', disabled: requestStatus === 'reject', handleClick: () => handleOpenRejectModal('reject'),
    },
    {
      icon: <CheckCircleRoundedIcon />, name: 'Approve', disabled: requestStatus === 'approve', handleClick: () => openConfirmModal('approve'),
    },
  ];

  const userAction = actions.filter((item) => ['Reject', 'Approve'].indexOf(item.name) === -1);

  return (
    <div className="account-details__form-group">
      {pendingAccountDetailsFields.map((item) => (
        <div key={item.name}>
          {userRole === 'user' && item.userVisible === false ? '' : <MultiInputFields item={item} />}
        </div>
      ))}
      <DataProtectionFields accountListType="pending" />
      <div className="account-details__button-group">
        <SpeedDial actions={userRole === 'admin' ? actions : userAction} />
      </div>
      <ConfirmModal
        type={actionType}
        name={account.project_name}
        show={openModal}
        handleClose={() => setOpenModal(false)}
        handleSubmit={handleSubmit}
      />
      <RejectModal
        show={openRejectModal}
        handleClose={() => setOpenRejectModal(false)}
        handleSubmit={(value) => handleSubmit(value)}
      />
    </div>
  );
};

export default PendingAccount;
