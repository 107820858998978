import { createSlice } from '@reduxjs/toolkit';

const accountCreationSTepsSlice = createSlice({
  name: 'accountList',
  initialState: {
    step: 1,
    isValidated: false,
    isCompletedFilled: false,
    updatingAccount: false,
  },
  reducers: {
    handleNextStep: (state) => {
      state.step += 1;
    },
    handlePreviousStep: (state) => {
      state.step -= 1;
    },
    handleResetStep: (state) => {
      state.step = 1;
    },
    setIsValidated: (state, action) => {
      state.isValidated = action.payload;
    },
    setIsCompletedFilled: (state, action) => {
      state.isCompletedFilled = action.payload;
    },
    setUpdatingAccount: (state, action) => {
      state.updatingAccount = action.payload;
    },
  },
});

export const {
  handleNextStep,
  handlePreviousStep,
  handleResetStep,
  setIsValidated,
  setIsCompletedFilled,
  setUpdatingAccount,
} = accountCreationSTepsSlice.actions;

export default accountCreationSTepsSlice.reducer;
