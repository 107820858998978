import React, { FC } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';

interface Props {
  actions: {
    icon: any;
    name: string;
    disabled?: boolean;
    handleClick?: () => void
  }[];
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: 90,
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  disabled: {
    background: 'lightgrey',
    opacity: 0.5,
    '&:hover': {
      background: 'lightgrey',
    },
  },
}));

const OpenIconSpeedDial:FC<Props> = ({ actions }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [hidden] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={classes.speedDial}
        hidden={hidden}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{ size: 'large', style: { backgroundColor: '#000000' } }}
        direction="left"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.disabled ? undefined : action.handleClick}
            tooltipPlacement="top"
            disableHoverListener={action.disabled}
            className={action.disabled ? classes.disabled : undefined}
          />
        ))}
      </SpeedDial>
    </div>
  );
};

export default OpenIconSpeedDial;
