export interface Rootstate {
  accounts: {
    account: Account & Contact;
    project: Project & Contact;
    selectedAccount: string;
    selectedProject: string;
    directory: string;
    status: any;
  };
  step: {
    updatingAccount: boolean;
    isValidated: boolean;
    step: number;
    isCompletedFilled: boolean;
  };
  user: {
    userRole: string;
  }
}

interface Contact {
  [key: string]: {
    [key: string]: string;
  }
}

interface Account {
 [key: string]: string;
}

interface Project {
  [key: string]: string;
 }

export interface Alert {
  open: boolean;
  type: string;
  message?: string;
}

export interface PreviousAccount {
  project_name: string;
  account_project: string;
}

export interface PreviousProject {
  project_name: string;
  project_id: string;
}

export interface AdditionalOwner {
  email: string;
  full_name: string;
}

export const mandatoryFields = [
  'account_project',
  'account_description',
  'project_name',
  'budget_limit_alert',
];

export const contactsMandatoryFields = [
  'security_contact',
  'operations_contact',
  'requestor_contact',
];

export const projectMandatoryFields = [
  'project_name',
  'project',
  'project_owner',
  'project_finops_contact',
  'project_accounts',
];
