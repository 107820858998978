import axios from 'axios';

const authority = process.env.REACT_APP_AUTHORITY;
const clientId = process.env.REACT_APP_CLIENT_ID;

export const axiosInstance = async () => {
  const userSession = sessionStorage.getItem(`oidc.user:${authority}:${clientId}`) || '';
  let token = await JSON.parse(userSession).id_token;
  // set static values when running on local env
  if (process.env.REACT_APP_ENV === 'local') {
    token = process.env.REACT_APP_TEST_TOKEN;
  }

  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URI,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    validateStatus(status) {
      return status < 500;
    },
  });
};
