import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../axios';
import { accountRootStatus } from '../../helpers/options';
import { FilterAccounts, AccountsState } from './slicesHelpers';

export const fetchActiveAccounts = createAsyncThunk('accountList/fetchActiveAccounts',
  async () => axiosInstance()
    .then((service) => service?.get('/accounts?status=active')
      .then((res) => res.data)));

export const fetchPendingAccounts = createAsyncThunk('accountList/fetchPendingAccounts',
  async () => axiosInstance()
    .then((service) => service?.get('/requests?action=create')
      .then((res) => res.data)));

export const fetchAccountsToBeDeleted = createAsyncThunk('accountList/fetchAccountsToBeDeleted',
  async () => axiosInstance()
    .then((service) => service?.get('/requests?action=suspend')
      .then((res) => res.data)));

export const fetchProjects = createAsyncThunk('projectList/fetchProjects',
  async () => axiosInstance()
    .then((service) => service?.get('/projects')
      .then((res) => res.data.projects)));

export const fetchClosedAccounts = createAsyncThunk('accountList/fetchClosedAccounts',
  async () => axiosInstance()
    .then((service) => service?.get('/accounts?status=closed')
      .then((res) => res.data)));

export const filterAccounts = createAsyncThunk('accountList/filterAccounts',
  async ({ filterName, filterValue }: FilterAccounts) => axiosInstance()
    .then((service) => service?.get(`/search?filter_name=${filterName}&filter_value=${filterValue}`)
      .then((res) => res.data)));

export const filterAccountNames = createAsyncThunk('accountList/filterAccountNames',
  async ({ filterName, filterValue }: FilterAccounts) => axiosInstance()
    .then((service) => service?.get(`/search?filter_name=${filterName}&filter_value=${filterValue}`)
      .then((res) => res.data)));

export const fetchActiveAccountStatus = createAsyncThunk('accountStatus/getActiveStatus',
  async (account_id: string) => axiosInstance()
    .then((service) => service?.get(`/accounts/${account_id}/?query=status`)
      .then((res) => res.data)));

export const fetchPendingAccountStatus = createAsyncThunk('accountStatus/getPendingStatus',
  async (account_id: string) => axiosInstance()
    .then((service) => service?.get(`/requests/${account_id}/?query=status`)
      .then((res) => res.data)));

const initialState = {
  account: {
    action: 'create',
    account_brand: '',
    account_env: '',
    account_project: '',
    account_spec: '',
    cost_center: '',
    cost_center_wa: '',
    cost_psp_element: '',
    cost_assignment: '',
    account_description: '',
    regions: ['eu-central-1', 'us-east-1'],
    operations_contact: {
      full_name: '',
      email: '',
    },
    security_contact: {
      full_name: '',
      email: '',
    },
    requestor_contact: {
      full_name: '',
      email: '',
    },
    requestor_email: '',
    account_name: '',
    project_name: '',
    budget_limit_alert: '0,00',
    leanix_id: '',
    architect_assessment_performed: false,
    architect_assessment_date: null,
    architect_fullname: '',
    security_checkup_performed: false,
    security_checkup_date: null,
    additional_owners: [],
    new_additional_owner: {
      email: '',
      full_name: '',
    },
  },
  project: {
    project_name: '',
    project: '',
    project_description: '',
    project_owner: {
      full_name: '',
      email: '',
      department: '',
    },
    project_finops_contact: {
      full_name: '',
      email: '',
    },
    project_cost_center: '',
    project_cost_psp_element: '',
    project_cost_assignment: '',
    project_budget_limit_alert: '0,00',
    project_accounts: [{ name: '', id: '' }],
    project_status: '',
  },
  selectedAccount: '',
  selectedProject: '',
  directory: '',
  activeAccounts: [],
  closedAccounts: [],
  accountsToBeDeleted: [],
  pendingAccounts: [],
  projects: [],
  filteredAccount: [],
  accountNameAvailable: true,
  status: accountRootStatus,
} as AccountsState;

const accountListSlice = createSlice({
  name: 'accountList',
  initialState,
  reducers: {
    selectAccount: (state, action) => {
      state.selectedAccount = action.payload.accountId;
      state.selectedProject = '';
      state.directory = action.payload.directory;
    },
    selectProject: (state, action) => {
      state.selectedProject = action.payload.projectItSystem;
      state.selectedAccount = '';
      state.directory = action.payload.directory;
    },
    editAccount: (state, action) => {
      state.account = action.payload;
    },
    editProject: (state, action) => {
      state.project = action.payload;
    },
    resetAccountStatusCards: (state, action) => {
      state.status = accountRootStatus;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchActiveAccounts.fulfilled, (state, { payload }) => {
      state.activeAccounts = payload.length > 0 ? payload
        .sort((a: any, b: any) => ((
          a.account_label.toLowerCase() > b.account_label.toLowerCase()) ? 1 : -1
        )) : payload;
    });
    builder.addCase(fetchProjects.fulfilled, (state, { payload }) => {
      state.projects = payload.length > 0 ? payload
        .sort((a: any, b: any) => ((
          a.project_name.toLowerCase() > b.project_name.toLowerCase()) ? 1 : -1
        )) : payload;
    });
    builder.addCase(fetchPendingAccounts.fulfilled, (state, { payload }) => {
      state.pendingAccounts = payload.length > 0 ? payload
        .sort((a: any, b: any) => ((
          a.account_label.toLowerCase() > b.account_label.toLowerCase()) ? 1 : -1
        )) : payload;
    });
    builder.addCase(fetchAccountsToBeDeleted.fulfilled, (state, { payload }) => {
      state.accountsToBeDeleted = payload.length > 0 ? payload
        .sort((a: any, b: any) => ((
          a.account_label.toLowerCase() > b.account_label.toLowerCase()) ? 1 : -1
        )) : payload;
    });
    builder.addCase(fetchClosedAccounts.fulfilled, (state, { payload }) => {
      state.closedAccounts = payload.length > 0 ? payload
        .sort((a: any, b: any) => ((
          a.account_label.toLowerCase() > b.account_label.toLowerCase()) ? 1 : -1
        )) : payload;
    });
    builder.addCase(filterAccounts.fulfilled, (state, { payload }) => {
      state.activeAccounts = payload.active_accounts.length > 0
        ? payload.active_accounts.sort((a: any, b: any) => ((
          a.account_label.toLowerCase() > b.account_label.toLowerCase()) ? 1 : -1
        )) : payload.active_accounts;
      state.pendingAccounts = payload.pending_accounts.length > 0
        ? payload.pending_accounts.sort((a: any, b: any) => ((
          a.account_label.toLowerCase() > b.account_label.toLowerCase()) ? 1 : -1
        )) : payload.pending_accounts;
      state.accountsToBeDeleted = payload.accounts_to_be_deleted.length > 0
        ? payload.accounts_to_be_deleted.sort((a: any, b: any) => ((
          a.account_label.toLowerCase() > b.account_label.toLowerCase()) ? 1 : -1
        )) : payload.accounts_to_be_deleted;
      state.closedAccounts = payload.closed_accounts.length > 0
        ? payload.closed_accounts.sort((a: any, b: any) => ((
          a.account_label.toLowerCase() > b.account_label.toLowerCase()) ? 1 : -1
        )) : payload.closed_accounts;
    });
    builder.addCase(filterAccountNames.fulfilled, (state, { payload }) => {
      if (payload.active_accounts.length > 0
        || payload.pending_accounts.length > 0
        || payload.accounts_to_be_deleted.length > 0
        || payload.closed_accounts.length > 0) {
        state.accountNameAvailable = false;
      } else {
        state.accountNameAvailable = true;
      }
    });
    builder.addCase(fetchActiveAccountStatus.fulfilled, (state, { payload }) => {
      state.status = payload;
    });
    builder.addCase(fetchPendingAccountStatus.fulfilled, (state, { payload }) => {
      state.status = payload;
    });
  },
});

export const {
  selectAccount,
  selectProject,
  editAccount,
  editProject,
  resetAccountStatusCards,
} = accountListSlice.actions;

export default accountListSlice.reducer;
