import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Button } from '@audi/audi-ui-react';
import { Rootstate } from '../AccountDetailsHelpers';

interface Props {
  show: boolean;
  name: string;
  handleClose: () => void;
  handleSubmit: () => void;
  type: string;
}

const ConfirmModal: FC<Props> = ({
  show, name, handleClose, type, handleSubmit,
}) => {
  const account = useSelector((state: Rootstate) => state.accounts.account);
  const project = useSelector((state: Rootstate) => state.accounts.project);
  const userRole = useSelector((state: Rootstate) => state.user.userRole);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setDisabled(false);
  }, [show]);
  const postProcessChecks = () => {
    if (userRole !== 'admin') {
      if (
        (account.cost_center === '' && account.cost_psp_element === '')) {
        return 'Cost Center or Cost PSP Element must be set';
      }
      if ((account.cost_center.length > 0 && account.cost_psp_element.length > 0)) {
        return 'Cost Center and Cost PSP Element cannot be set at the same time';
      }
    }
    return true;
  };

  const renderMessages = () => {
    if (type === 'save new') {
      return (
        <p>
          you want to save
          {' '}
          <strong>{name}</strong>
          {' '}
          as a new request?
        </p>
      );
    }
    if (type === 'save') {
      return (
        <p>
          you want to save the changes for
          {' '}
          <strong>{name}</strong>
          {' '}
          ?
        </p>
      );
    }
    if (type === 'delete') {
      return (
        <p>
          you want to delete
          {' '}
          <strong>{name}</strong>
          {' '}
          ?
        </p>
      );
    }
    if (type === 'approve') {
      return (
        <p>
          you want to approve
          {' '}
          <strong>{name}</strong>
          {' '}
          ?
        </p>
      );
    }
    if (type === 'reject') {
      return (
        <p>
          Please provide a reason for the rejection
        </p>
      );
    }
    if (type === 'cancel') {
      return (
        <p>
          you want to cancel account deletion request for
          {' '}
          <strong>{name}</strong>
        </p>
      );
    }
  };

  if (postProcessChecks() === true) {
    return (
      <div>
        <Modal
          size="lg"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body style={{ display: 'flex', alignItems: 'center', padding: '0 40px' }}>
            <div style={{
              fontSize: '24px', textAlign: 'center', width: '100%', marginTop: '40px',
            }}
            >
              {type !== 'reject' && <p> Are you sure that</p>}
              {renderMessages()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              size="small"
              onClick={() => { setDisabled(true); handleSubmit(); }}
              disabled={disabled}
            >
              {type === 'save new' && 'Save as new'}
              {type === 'save' && 'Save'}
              {type === 'delete' && 'Delete'}
              {type === 'approve' && 'Approve'}
              {type === 'cancel' && 'Confirm'}
            </Button>
            <Button variant="secondary" size="small" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
  return (
    <div>
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body style={{ display: 'flex', alignItems: 'center', padding: '0 40px' }}>
          <div style={{
            fontSize: '24px', textAlign: 'center', width: '100%', marginTop: '40px',
          }}
          >
            <p>Incorrect data fields: </p>
            {postProcessChecks()}
            .
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="small" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
