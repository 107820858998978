import React, { FC, useEffect, useState } from 'react';
import './AccountList.scss';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import SearchIcon from '@material-ui/icons/Search';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {
  Button,
} from '@audi/audi-ui-react';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import {
  selectAccount, selectProject, fetchActiveAccounts, editAccount, fetchPendingAccounts,
  fetchAccountsToBeDeleted, fetchClosedAccounts, filterAccounts, fetchProjects,
} from '../../app/slices/accountListSlice';
import { accountState } from '../../helpers/objects';
import { accountFilterOptions } from '../../helpers/options';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
});

const useSelectStyles = makeStyles((theme: Theme) => createStyles({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

interface RootState {
  accounts: Accounts;
  user: {
    userRole: string;
  }
}

interface Account {
  account_name: string;
  cloudProvider: string;
  description: string;
  account_id: string;
  request_id: string;
  department: string;
  project_name: string;
  account_label: String;
}

interface PendingAccounts {
  action: string;
  project_name: string;
  account_label: string;
  request_id: string;
}

interface ProjectAccount {
  account_name: string;
  account_id: string;
  account_env: string;
  account_label: string;
  account_status: string;
}

interface Project {
  project_name: string,
  project_id: string,
  accounts: ProjectAccount[]
}

interface Accounts {
  selectedAccount: string;
  selectedProject: string;
  activeAccounts: Account[];
  closedAccounts: Account[];
  accountsToBeDeleted: Account[];
  pendingAccounts: PendingAccounts[];
  projects: Project[];
}

interface Props {
  openCreateAccountModal: (x: boolean) => void;
}

const AccountList: FC<Props> = ({ openCreateAccountModal }) => {
  const classes = useStyles();
  const classes2 = useSelectStyles();
  const userRole = useSelector((state: RootState) => state.user.userRole);
  const activeAccounts = useSelector((state: RootState) => state.accounts.activeAccounts);
  const closedAccounts = useSelector((state: RootState) => state.accounts.closedAccounts);
  const accountsToBeDeleted = useSelector((state: RootState) => state.accounts.accountsToBeDeleted);
  const pendingAccounts = useSelector((state: RootState) => state.accounts.pendingAccounts);
  const projects = useSelector((state: RootState) => state.accounts.projects);
  const [filterType, setFilterType] = useState('');
  const [filterInput, setFilterInput] = useState('');
  const [defaultExpanded, setDefaultExpanded] = useState<string[]>([]);
  const dispatch = useDispatch();

  const fetchAllAccounts = () => {
    dispatch(fetchActiveAccounts());
    dispatch(fetchPendingAccounts());
    if (userRole === 'admin') {
      dispatch(fetchAccountsToBeDeleted());
    }
    dispatch(fetchClosedAccounts());
    if (userRole === 'admin') {
      dispatch(fetchProjects());
    }
    setDefaultExpanded([]);
  };

  const onSubmitFilter = async () => {
    try {
      await dispatch(filterAccounts({ filterName: filterType, filterValue: filterInput }));
      setDefaultExpanded(['0', '1', '2', '3']);
    } catch (error) {
      return error;
    }
  };

  const onEnterFilter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onSubmitFilter();
    }
  };

  useEffect(() => {
    fetchAllAccounts();
  }, [dispatch, userRole]);

  return (
    <div className="account-list">
      <div className="account-list__header">
        Accounts
      </div>
      <Button
        variant="primary"
        size="small"
        onClick={() => {
          dispatch(editAccount(accountState));
          dispatch(selectAccount(''));
          dispatch(selectProject(''));
          openCreateAccountModal(true);
        }}
      >
        + New Account
      </Button>

      <div className="account-list__filter">
        <FormControl className={classes2.formControl}>
          <InputLabel id="demo-mutiple-name-label">Filter By</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={filterType}
            onChange={(e: any) => setFilterType(e.target.value)}
          >
            <MenuItem
              value=""
              onClick={() => { fetchAllAccounts(); setFilterInput(''); dispatch(selectAccount('')); dispatch(selectProject('')); }}
            >
              <em>None</em>
            </MenuItem>
            {accountFilterOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="standard-basic"
          label="Search"
          onChange={(e) => setFilterInput(e.target.value)}
          onKeyDown={onEnterFilter}
          value={filterInput}
          disabled={!filterType}
        />
        <button
          type="button"
          aria-label="Search"
          className="account-list__search-btn"
          disabled={!filterInput}
          onClick={onSubmitFilter}
        >
          <SearchIcon />
        </button>
      </div>
      <div className="scrollable-div">
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ArrowDropUpIcon />}
          defaultExpandIcon={<ArrowDropDownIcon />}
          expanded={defaultExpanded}
          onNodeToggle={(event, nodeIds: string[]) => setDefaultExpanded(nodeIds)}
        >
          {(userRole === 'admin' && accountsToBeDeleted?.length) ? (
            <TreeItem
              nodeId="0"
              label={(
                <div>
                  <FolderOutlinedIcon fontSize="small" />
                  <span style={{ marginLeft: 8 }}><b>Accounts to be deleted</b></span>
                </div>
              )}
            >
              <div className="account-list__child-item">
                {accountsToBeDeleted && accountsToBeDeleted.map((account) => (
                  <TreeItem
                    key={account.request_id}
                    nodeId={account.request_id}
                    label={account.account_label}
                    onClick={() => dispatch(selectAccount({ accountId: account.request_id, directory: 'accountToBeDeleted' }))}
                  />
                ))}
              </div>
            </TreeItem>
          ) : null}

          {closedAccounts?.length
            ? (
              <TreeItem
                nodeId="1"
                label={(
                  <div>
                    <FolderOutlinedIcon fontSize="small" />
                    <span style={{ marginLeft: 8 }}><b>Closed accounts</b></span>
                  </div>
                )}
              >
                <div className="account-list__child-item">
                  {closedAccounts && closedAccounts.map((account) => (
                    <TreeItem
                      key={account.account_id}
                      nodeId={account.account_id}
                      label={account.account_label}
                      onClick={() => dispatch(selectAccount({ accountId: account.account_id, directory: 'closedAccount' }))}
                    />
                  ))}
                </div>
              </TreeItem>
            ) : null}

          {pendingAccounts?.length
            ? (
              <TreeItem
                nodeId="2"
                label={(
                  <div>
                    <FolderOutlinedIcon fontSize="small" />
                    <span style={{ marginLeft: 8 }}><b>Pending accounts</b></span>
                  </div>
                )}
              >
                <div className="account-list__child-item">
                  {pendingAccounts && pendingAccounts.map((account) => (
                    <TreeItem
                      key={account.request_id}
                      nodeId={account.request_id}
                      label={account.account_label}
                      onClick={() => dispatch(selectAccount({ accountId: account.request_id, directory: 'pending account' }))}
                    />
                  ))}
                </div>
              </TreeItem>
            ) : null}

          {activeAccounts?.length
            ? (
              <TreeItem
                nodeId="3"
                label={(
                  <div>
                    <FolderOutlinedIcon fontSize="small" />
                    <span style={{ marginLeft: 8 }}><b>Active accounts</b></span>
                  </div>
                )}
              >
                <div className="account-list__child-item">
                  {activeAccounts && activeAccounts.map((account) => (
                    <TreeItem
                      key={account.account_id}
                      nodeId={account.account_id}
                      label={account.account_label}
                      onClick={() => dispatch(selectAccount({ accountId: account.account_id, directory: 'active account' }))}
                    />
                  ))}
                </div>
              </TreeItem>
            ) : null}
          {projects?.length
            ? (
              <TreeItem
                nodeId="4"
                label={(
                  <div>
                    <FolderOutlinedIcon fontSize="small" />
                    <span style={{ marginLeft: 8 }}><b>Projects</b></span>
                  </div>
                )}
              />
            ) : null}
        </TreeView>
      </div>
    </div>
  );
};

export default AccountList;
