import React, { useState } from 'react';
import '../AccountAddInfos.scss';
import { documentationLinks } from '../../../helpers/options';

const AcsDocumentation = () => {
  const title = 'ACS Documentation';
  const [visibility, setVisibility] = useState(true);

  const toggleContent = () => {
    setVisibility(!visibility);
  };

  console.log(documentationLinks);

  return (
    <div>
      <button
        type="button"
        className={`add-info__sub-header collapsible ${visibility ? 'active' : ''}`}
        onClick={toggleContent}
      >
        { title }
      </button>
      {visibility && (
      <div className="content">
        <ul className="urls-list">
          { documentationLinks.map((docuLink) => (
            <li key={docuLink.title}>
              <a
                href={docuLink.value}
                target="_blank"
                rel="noreferrer noopener"
                className="add-info__urls"
              >
                {docuLink.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      )}
    </div>
  );
};

export default AcsDocumentation;
